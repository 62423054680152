import axios from 'axios';
import { TUserParamsSearch, TStaffSearch } from '../components/ui/UsersFilter/types';
import {
  CREATE_USER_URL,
  getUserUrl,
  SEND_INVITE_URL,
  delUserUrl,
  uploadUserAvatarUrl,
} from '../constants';
import { IUserAddProps } from '../interfaces/user';

export function getUsersByRole(params: TUserParamsSearch) {
  let url = `/api/users/search`;
  let { filter } = params;

  if (Object.keys(filter).length > 0) {
    for (const key of Object.keys(filter)) {
      if (filter[key].length > 0) {
        url += `&${key}=${filter[key].join(',')}`;
      }
    }
  }

  return axios.get(url, {
    params: {
      ...params,
    },
  });
}

export function getSearchUsers(params: Partial<TStaffSearch>) {
  let url = `/api/users/search`;
  return axios.get(url, { params });
}

export function getUser(id: number) {
  return axios.get(getUserUrl(id));
}

export function createUser(data: IUserAddProps) {
  return axios.post(CREATE_USER_URL, data);
}

export function editUser(id: number, data: any) {
  return axios.put(`/api/user/${id}/edit`, data, {
    params: {
      // languages:
      //   data.languages && data.languages.length > 0 ? data.languages.join(',') : '%20',
      // country: data.country || '%20',
    },
  });
}

export function sendInvite(data: string) {
  return axios.get(`${SEND_INVITE_URL}`, { params: { email: data } });
}

export function delUser(id: number) {
  return axios.delete(delUserUrl(id));
}

export function uploadUserAvatar({ photo, id }: { photo: FormData; id: string }) {
  return axios.post(uploadUserAvatarUrl(id), photo);
}

export function deleteUserPhoto(id: string) {
  return axios.delete(`/api/user/${id}/delete_photo`);
}

export function deleteMePhoto() {
  return axios.delete(`/api/me/delete_photo`);
}
export function addDeviceUser({ platform, token }: { platform: string; token: string }) {
  return axios.post(`/api/user_device/add`, { platform, token });
}
export function pushUser({
  user_id,
  platform,
  message,
}: {
  user_id: number;
  platform: string;
  message: string;
}) {
  return axios.post(`/api/user_device/message`, undefined, {
    params: { message, platform, user_id },
  });
}

export const editManagerRole = (
  manager_id: number,
  role: 'ROLE_VENDOR' | 'ROLE_VENDOR_STAFF' | 'ROLE_BUYER' | 'ROLE_BUYER_STAFF'
) => axios.put(`/api/companies/manager/${manager_id}`, {}, { params: { role } });
