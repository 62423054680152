import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Autocomplete, MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as tariffActions } from '../../../../../store/ducks/tariff.duck';
import { actions as productTypesActions } from '../../../../../store/ducks/productType.duck';
import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { IUser, UserRoles } from '../../../../../interfaces/user';
import homeStyles from '../../../../../constants/homeStyles';
import { useStylesEditPage } from '../hooks/useStyleEditPage';
import { useFormatMessage } from '../../../../../hooks';
import { IAppState } from '../../../../../store/rootDuck';
import { IProduct } from '../../../../../interfaces/product';
import { ITypeParameter } from '../../../../../interfaces/productType';
import NumberFormatCustom from '../../../../../components/formComponents/NumberFormatCustom';
import { ICountry } from '../../../../auth/interfaces';

interface TProps {
  user: IUser | null;
  loading: boolean;
  editProduct: (id: number, data: FormData) => void;
  setTabValue: (value: number) => void;
  countries: ICountry[];
  updateVehicle: boolean;
  setUpdateVehicle: (value: boolean) => void;
}

const getInitialValues = (product?: IProduct) => {
  const initValues: { [key: string]: any } = {
    name: product?.name || '',
    productTypeId: product?.product_type?.id,
    tariff_id: product?.tariff?.id || 0,
  };

  if (product?.parameter_values) {
    product.parameter_values.forEach(item => {
      initValues[`parameter${item.parameter_id}`] = item.value;
    });
  }

  return initValues;
};

const FormVehicle: React.FC<TProps> = ({
  user,
  countries,
  loading,
  editProduct,
  setTabValue,
  updateVehicle,
  setUpdateVehicle,
}) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStylesEditPage();
  const homeClasses = homeStyles();
  const intl = useIntl();

  const { types } = useSelector(
    (state: IAppState) => ({
      types: state.productTypes.typeParams,
    }),
    shallowEqual
  );

  const { tariffs } = useSelector(
    (state: IAppState) => ({
      tariffs: state.tariff.tariffs,
    }),
    shallowEqual
  );
  const product = user?.products?.[0];

  const filterCountries = useMemo(() => {
    if (countries) {
      const newList: ICountry[] = [];
      countries.forEach(i => {
        if (i.id === 1 || i.id === 76) {
          newList.push({ ...i, favorite: true });
        } else {
          newList.push(i);
        }
      });
      return newList;
    }
    return [];
  }, [countries]);

  useEffect(() => {
    if (updateVehicle) {
      handleSubmit();
      setUpdateVehicle(false);
    }
  }, [updateVehicle]);

  useEffect(() => {
    product?.product_type?.id &&
      dispatch(productTypesActions.fetchParamsByIdRequest({ id: product?.product_type.id }));
    product && dispatch(tariffActions.fetchRequest({ role: UserRoles.ROLE_VENDOR }));
  }, [product]);

  const validationSchema = useMemo(() => {
    const fields: any = {};
    const requiredParam = types?.filter((param: ITypeParameter) => !!param.required);
    requiredParam?.forEach(param => {
      fields[`parameter${param.id}`] =
        param.type === 'multiple_enum'
          ? Yup.array().min(1, fm('AUTH.VALIDATION.REQUIRED_FIELD'))
          : Yup.string().required(fm('AUTH.VALIDATION.REQUIRED_FIELD')).trim();
    });
    return Yup.object().shape({
      ...fields,
      name: Yup.string().required(fm('PRODUCT.REQUIRED.NAME')).trim(),
    });
  }, [types]);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: getInitialValues(product),
      enableReinitialize: true,
      validationSchema,
      onSubmit: submitValues => {
        const data = new FormData();
        data.append('name', submitValues.name);
        if (submitValues.productTypeId) {
          data.append('product_type_id', String(submitValues.productTypeId));
        }
        data.append('tariff_id', String(submitValues.tariff_id));
        if (submitValues.productTypeId) {
          types?.forEach((param: any) => {
            const { id: paramId, type } = param;
            if (type === 'multiple_enum') {
              if (submitValues[`parameter${paramId}`]) {
                if (submitValues[`parameter${paramId}`].length === 0) {
                  data.append(`parameter[${paramId}][]`, 'null');
                }
                submitValues[`parameter${paramId}`].forEach((value: string) => {
                  data.append(`parameter[${paramId}][]`, value);
                });
              }
            } else if (
              submitValues[`parameter${paramId}`] &&
              submitValues[`parameter${paramId}`] !== ''
            ) {
              data.append(
                `parameter[${paramId}]`,
                submitValues[`parameter${paramId}`].toString()
              );
            } else {
              data.append(`parameter[${paramId}]`, 'null');
            }
          });
        }
        product?.id && editProduct(product.id, data);
      },
    });

  return (
    <div className={homeClasses.classes.form}>
      <TextField
        type='text'
        label={fm('title')}
        margin='normal'
        name='name'
        value={values.name || ''}
        variant='outlined'
        onBlur={handleBlur('name')}
        onChange={handleChange}
        // @ts-ignore
        helperText={touched.name && errors.name}
        error={Boolean(touched.name && errors.name)}
      />
      <TextField
        select
        margin='normal'
        label={fm('USER.TABLE.TARIFF')}
        value={values.tariff_id}
        onChange={handleChange}
        name='tariff_id'
        variant='outlined'
      >
        {tariffs.map(i => (
          <MenuItem key={i.id} value={i.id}>
            {i.name}
          </MenuItem>
        ))}
      </TextField>
      {types?.map(item => {
        if (item.type === 'number') {
          return (
            <TextField
              type='text'
              label={item.name}
              margin='normal'
              name={`parameter${item.id}`}
              value={values[`parameter${item.id}`] || ''}
              variant='outlined'
              onBlur={handleBlur(`parameter${item.id}`)}
              onChange={handleChange(`parameter${item.id}`)}
              // @ts-ignore
              helperText={touched[`parameter${item.id}`] && errors[`parameter${item.id}`]}
              error={Boolean(touched[`parameter${item.id}`] && errors[`parameter${item.id}`])}
              key={item.id}
              InputProps={{
                inputComponent: item.name !== 'Год' ? (NumberFormatCustom as any) : undefined,
              }}
            />
          );
        }
        if (item.type === 'enum') {
          return (
            <>
              <Autocomplete
                key={item.id}
                options={
                  item.parameter_enum_values?.sort((a, b) =>
                    // eslint-disable-next-line no-nested-ternary
                    a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                  ) || []
                }
                autoHighlight
                style={{ marginTop: 16 }}
                value={item.parameter_enum_values?.find(
                  i => i.value === values[`parameter${item.id}`]
                )}
                onChange={(_event: any, newValue) => {
                  setFieldValue(`parameter${item.id}`, newValue?.value || '');
                }}
                groupBy={i =>
                  intl.formatMessage({
                    id: i.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                  })
                }
                noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                // disableClearable
                getOptionLabel={option => option.value || ''}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    value={option.value}
                  >
                    {option.value}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={item.name}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      style: { fontFamily: 'Montserrat' },
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </>
          );
        }

        if (item.type === 'string') {
          return (
            // <div className={classes.containerName} key={item.id}>
            <TextField
              type='text'
              key={item.id}
              variant='outlined'
              margin='normal'
              label={`${item.name}`}
              name={`parameter${item.id}`}
              value={values[`parameter${item.id}`] || ''}
              onBlur={handleBlur(`parameter${item.id}`)}
              onChange={handleChange(`parameter${item.id}`)}
              // @ts-ignore
              helperText={touched[`parameter${item.id}`] && errors[`parameter${item.id}`]}
              error={Boolean(touched[`parameter${item.id}`] && errors[`parameter${item.id}`])}
              // className={classes.fieldName}
            />
            // </div>
          );
        }

        if (item.type === 'text') {
          return (
            <>
              <TextField
                key={item.id}
                type='text'
                label={`${item.name}`}
                margin='normal'
                name={`parameter${item.id}`}
                value={values[`parameter${item.id}`] || ''}
                // @ts-ignore
                helperText={touched[`parameter${item.id}`] && errors[`parameter${item.id}`]}
                error={Boolean(
                  touched[`parameter${item.id}`] && errors[`parameter${item.id}`]
                )}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </>
          );
        }

        if (item.type === 'multiple_enum') {
          return (
            <>
              <Autocomplete
                key={item.id}
                options={
                  item.parameter_enum_values?.sort((a, b) =>
                    // eslint-disable-next-line no-nested-ternary
                    a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                  ) || []
                }
                multiple
                autoHighlight
                style={{ marginTop: 16 }}
                value={
                  values[`parameter${item.id}`]
                    ? values[`parameter${item.id}`].map((i: string) => {
                        const find = item.parameter_enum_values?.find(el => el.value === i);
                        return find;
                      })
                    : []
                }
                onChange={(_event: any, newValue) => {
                  newValue &&
                    setFieldValue(
                      `parameter${item.id}`,
                      newValue.map(i => i?.value)
                    );
                }}
                groupBy={i =>
                  intl.formatMessage({
                    id: i?.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                  })
                }
                noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                disableClearable
                getOptionLabel={option => option && option.value}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    value={option?.value || ''}
                  >
                    {option.value}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={item.name}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      style: { fontFamily: 'Montserrat' },
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </>
          );
        }

        if (item.type === 'country') {
          return (
            <>
              <Autocomplete
                key={item.id}
                id='country-select-demo'
                options={filterCountries.sort((a, b) =>
                  // eslint-disable-next-line no-nested-ternary
                  a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                )}
                autoHighlight
                style={{ marginTop: 16 }}
                value={filterCountries.find(i => i.name === values[`parameter${item.id}`])}
                onChange={(_event: any, newValue) => {
                  newValue && setFieldValue(`parameter${item.id}`, newValue.name);
                }}
                groupBy={i => intl.formatMessage({ id: i.favorite ? 'FAVORITES' : 'OTHER' })}
                noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                disableClearable
                getOptionLabel={option => option.ru_name}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                    value={option.id}
                  >
                    {option.ru_name} +{option.code}
                  </Box>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={item.name}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      style: { fontFamily: 'Montserrat' },
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </>
          );
        }
      })}
      <div className={classes.actions}>
        <ButtonWithLoader
          onPress={() => {
            handleSubmit();
            setTabValue(2);
          }}
          type='button'
          variant='contained'
          color='secondary'
          disabled={loading}
        >
          {fm('COMMON.BUTTON.BACK')}
        </ButtonWithLoader>

        <ButtonWithLoader
          marginLeft={20}
          marginRight={20}
          onPress={handleSubmit}
          disabled={loading}
        >
          {fm('USER.BUTTON.EDIT')}
        </ButtonWithLoader>

        <ButtonWithLoader
          type='button'
          variant='contained'
          color='secondary'
          onPress={() => navigate(-1)}
          disabled={loading}
        >
          {fm('COMMON.BUTTON.CLOSE')}
        </ButtonWithLoader>
      </div>
    </div>
  );
};

export default FormVehicle;
