import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as builder from '../../ducks/builder';
import TermDialog from '../../../app/components/other/Dialog/TermDialog';
import { LayoutContextConsumer } from '../LayoutContext';
import { useFormatMessage } from '../../../app/hooks';
import { IAppState } from '../../../app/store/rootDuck';

type TProps = {
  collections: any;
};

const Footer: React.FC<TProps> = ({ collections }) => {
  const fm = useFormatMessage();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogContentType, setDialogContentType] = React.useState('');

  const handleDialogOpen = useCallback((contentType: string) => {
    setDialogContentType(contentType);
    setOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const clickCollection = useCallback((item: any) => {
    const params: any = {};
    const paramsArray: any = [];
    item.parameters.forEach((param: any) => {
      if (param.filter_type) {
        params[param.parameter] = { values: param.values };
        paramsArray.push({
          parameter: param.parameter,
          values: param.values,
        });
      }
    });
    const productsFilter = {
      price_to:
        item.max_price && Number.isInteger(item.max_price) ? item.max_price.toString() : '',
      price_from:
        item.min_price && Number.isInteger(item.min_price) ? item.min_price.toString() : '',
      parameters: params,
    };

    navigate('/products/catalog', {
      state: {
        filter: productsFilter,
        productTypeId: item.product_type_id,
        paramsArray,
      },
    });
  }, []);

  const { footerClasses, footerContainerClasses } = useSelector((store: IAppState) => ({
    footerClasses: builder.selectors.getClasses(store, {
      path: 'footer',
      toString: true,
    }),
    footerContainerClasses: builder.selectors.getClasses(store, {
      path: 'footer_container',
      toString: true,
    }),
  }));

  return (
    <LayoutContextConsumer>
      {({ footer: { show } }: any) =>
        show && (
          <div
            className={`kt-footer ${footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
            id='kt_footer'
          >
            <div className={`kt-container ${footerContainerClasses}`}>
              <div className='kt-footer__copyright'>
                <div>
                  {fm('CREATOR')}&nbsp;
                  <a
                    href='https://start-mobile.net'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    Start Mobile
                  </a>
                  <br />
                  <a
                    rel='noopener noreferrer'
                    className='kt-link'
                    onClick={() => handleDialogOpen('user_agreement')}
                  >
                    {fm('AUTH.GENERAL.LEGAL')}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('privacy_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    {fm('AUTH.GENERAL.PRIVACY')}
                  </a>
                  {/* &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('shipping_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    {fm('SUBMENU.SHIPPING')}
                  </a>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a
                    onClick={() => handleDialogOpen('refund_policy')}
                    rel='noopener noreferrer'
                    className='kt-link'
                  >
                    {fm('REFUND')}
                  </a>
                  <br /> */}
                  {/* {collections && collections.length !== 0 && (
                    <>
                      {fm('COLLECTIONS')}:&nbsp;
                      {collections.map((item: any, i: any) => (
                        <React.Fragment key={item.id}>
                          {i > 0 && <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>}
                          <a
                            onClick={() => clickCollection(item)}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='kt-link'
                          >
                            {item.name}
                          </a>
                        </React.Fragment>
                      ))}
                    </>
                  )} */}
                </div>
                {/*<div className='kt-footer__menu'></div>*/}
                <>
                  <TermDialog
                    isOpen={openDialog}
                    handleClose={handleDialogClose}
                    contentType={dialogContentType}
                  />
                </>
              </div>
            </div>
          </div>
        )
      }
    </LayoutContextConsumer>
  );
};

export default Footer;
