import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { ICountry } from '../../../pages/auth/interfaces';
import { defaultCountry } from '../../../pages/auth/hooks/useHandleCountrySelect';

type TProps = {
  countries: ICountry[];
  countryId: number;
  setCountryId: (id: number) => void;
  countriesLoading: boolean;
  showCode?: boolean;
  label?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  disabled?: boolean;
};

const CountrySelect: React.FC<TProps> = ({
  countries,
  countryId,
  setCountryId,
  countriesLoading,
  showCode,
  variant = 'outlined',
  label,
  disabled,
}) => {
  const filterCountries = useMemo(() => {
    if (countries) {
      const newList: ICountry[] = [];
      countries.forEach(i => {
        if (i.id === 1 || i.id === 76) {
          newList.push({ ...i, favorite: true });
        } else {
          newList.push(i);
        }
      });
      return newList;
    }
    return [];
  }, [countries]);

  const intl = useIntl();

  return (
    <Autocomplete
      id='country-select-demo'
      options={filterCountries.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
      )}
      autoHighlight
      disabled={disabled}
      style={{ marginTop: 16 }}
      sx={{ minWidth: 200 }}
      value={filterCountries.find(i => i.id === countryId) || defaultCountry}
      loading={countriesLoading}
      onChange={(_event: any, newValue) => {
        newValue && setCountryId(newValue.id);
      }}
      groupBy={i => intl.formatMessage({ id: i.favorite ? 'FAVORITES' : 'OTHER' })}
      noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
      loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
      disableClearable
      getOptionLabel={option =>
        `${intl.locale === 'ru' ? option.ru_name : option.en_name} ${
          showCode ? `+${option.code}` : ''
        }`
      }
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
          value={option.id}
        >
          {intl.locale === 'ru' ? option.ru_name : option.en_name} +{option.code}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label || intl.formatMessage({ id: 'AUTH.INPUT.COUNTRIES' })}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            style: { fontFamily: 'Montserrat' },
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
