import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IProps {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onPress?: any;
  variant?: 'text' | 'outlined' | 'contained';
  style?: CSSProperties;
  theme?: ButtonTheme;
  margin?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  startIcon?: React.ReactNode;
}
export enum ButtonTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

const useStyles = makeStyles()(theme => {
  return {
    isLoading: {
      paddingRight: '3.5rem!important',
    },
    btn: {
      '&.MuiButton-root': {
        borderRadius: '0 !important',
        height: 50,
        minWidth: 135,
      },
    },
    primary: {
      backgroundColor: '#ffce00',
    },
    secondary: {
      '&.MuiButton-root': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    btnMarginRight: {
      marginRight: 25,
    },
    btnMarginLeft: {
      marginLeft: 25,
    },
    btnMargin: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: 20,
      },
    },
  };
});

const ButtonWithLoader: React.FC<IProps> = ({
  children,
  type = 'submit',
  disabled = false,
  loading = false,
  onPress = () => {},
  margin,
  marginRight = true,
  marginLeft = false,
  startIcon,
  theme = ButtonTheme.PRIMARY,
  ...props
}) => {
  const classes1 = useStyles();
  const { classes } = classes1;

  return (
    <Button
      {...props}
      type={type}
      style={{fontWeight: 'bold'}}
      variant='contained'
      color='primary'
      disabled={disabled}
      onClick={onPress}
      startIcon={startIcon}
      className={clsx({
        'btn btn-primary btn-elevate kt-login__btn-primary': true,
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
        [classes.isLoading]: loading,
        [classes[theme]]: true,
        [classes.btnMargin]: margin,
        [classes.btnMarginRight]: marginRight,
        [classes.btnMarginLeft]: marginLeft,
      })}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLoader;
