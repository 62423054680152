import React from 'react';
import { useIntl } from 'react-intl';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/Delete';
import ViewIcon from '@mui/icons-material/Visibility';
import { Col, Row } from 'react-bootstrap';

import TablePaginator from '../../../../components/TablePaginator/TablePaginator';
import { thousands } from '../../../../utils/utils';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import homeStyles from '../../homeStyles';
import { toAbsoluteUrl } from '../../../../../_base';
import { ISearch } from '../../../../interfaces/search';
import { API_DOMAIN } from '../../../../constants';
import { getFio } from '../../users/users/utils';
import { IUser } from '../../../../interfaces/user';
import { useDefineUserRole } from '../../../../hooks';
import { useStylesSearchList } from '../hooks/useStyles';

interface IProductsProps {
  data: ISearch[];
  page: number;
  perPage: number;
  total: number;
  deleteAction: (id: number) => void;
  fetch: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  me?: IUser;
}

const SearchList: React.FC<IProductsProps> = ({
  data,
  page,
  perPage,
  total,
  fetch,
  editAction,
  viewAction,
  deleteAction,
  me,
}) => {
  const intl = useIntl();
  const classes = useStylesSearchList();
  const homeClasses = homeStyles();
  const isAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  const translates: { [type: string]: string } = {
    new: intl.formatMessage({ id: 'ORDER.TYPE.NEW' }),
    canceled: intl.formatMessage({ id: 'ORDER.TYPE.CANCELED' }),
    completed: intl.formatMessage({ id: 'ORDER.TYPE.COMPLETED' }),
    in_processing: intl.formatMessage({ id: 'ORDER.TYPE.PROCCESS' }),
  };

  return (
    <Row>
      <Col>
        {!data || !data.length ? (
          <Typography className={classes.empty} component='h5' variant='h5'>
            {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
          </Typography>
        ) : (
          <>
            <Card className={classes.card}>
              <CardContent className={homeClasses.classes.tableContainer}>
                <Table aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'SEARCH.ID' })}
                      </SHeadTableCell>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'PUBLICATIONS.TABS.PHOTOS' })}
                      </SHeadTableCell>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'CATEGORIES.TABLE.NAME' })}
                      </SHeadTableCell>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'PRODUCT.EDIT.PRICE' })}
                      </SHeadTableCell>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'SEARCH.CREATOR' })}
                      </SHeadTableCell>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'SEARCH.STATUS' })}
                      </SHeadTableCell>
                      <SHeadTableCell className={classes.cell}>
                        {intl.formatMessage({ id: 'SEARCH.PRODUCTS' })}
                      </SHeadTableCell>
                      <SHeadTableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map(item => (
                      <TableRow key={item.id}>
                        <TableCell className={classes.cell} component='th' scope='row'>
                          {item.id || '-'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.attachments && item.attachments[0]?.path && (
                            <img
                              src={`${API_DOMAIN}/${item.attachments[0]?.path}`}
                              className={classes.img}
                              alt=''
                              onError={(e: any) => {
                                e.target.src = toAbsoluteUrl('/images/placeholder.png');
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <div
                            className={classes.titleName}
                            onClick={() => viewAction(item, false)}
                          >
                            {item.name}
                          </div>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {item.price ? thousands(String(item.price)) : '-'}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {/* {item.user && getFio(item.user)} */}
                          {item.fio}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {translates[item.status]}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <b
                            className={classes.titleProposals}
                            onClick={() => viewAction(item, true)}
                          >
                            {item.proposals_num
                              ? `${item.proposals_num} - ${intl.formatMessage({
                                  id: 'SEARCH.GOTO',
                                })}`
                              : 0}
                          </b>
                        </TableCell>
                        <TableCell>
                          <div>
                            {editAction &&
                              (isAdmin || me?.id?.toString() === item.user?.id.toString()) && (
                                <>
                                  <IconButton onClick={() => editAction(item)}>
                                    <EditOutlinedIcon color='error' className={classes.icon} />
                                  </IconButton>
                                </>
                              )}
                            {viewAction && (
                              <IconButton onClick={() => viewAction(item)}>
                                <ViewIcon color='primary' className={classes.icon} />
                              </IconButton>
                            )}
                            {(isAdmin || me?.id?.toString() === item.user?.id.toString()) && (
                              <IconButton
                                onClick={() => {
                                  deleteAction(item.id);
                                }}
                              >
                                <DeleteOutlineOutlinedIcon
                                  className={classes.iconDelete}
                                  color='error'
                                />
                              </IconButton>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {fetch && (
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page || 1}
                          realPerPage={data.length}
                          perPage={perPage || 20}
                          total={total || 0}
                          fetchRows={fetch}
                          label={intl.formatMessage({ id: 'PRODUCT.TABLE.PERPAGE' })}
                          rowsPerPageOptions={[12, 36, 108]}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </CardContent>
            </Card>
          </>
        )}
      </Col>
    </Row>
  );
};

export default SearchList;
