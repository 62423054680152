import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const {
  profile,
  vendorProducts,
  products,
  orders,
  users,
  legal,
  settings,
  catalog,
  blog,
  guestBlog,
  tariffs,
  productDashboard,
  // companies,
  // vendorUsers,
  vendorCompany,
  catalogForUser,
  chats,
  // tenders,
  buyersTenders,
  vendorsTenders,
  // addTender,
  // allTenders,
  buyersDocuments,
  buyersOrders,
  usersManager,
  shifts,
  statistic,
  vendorRequests,
  buyerRequests,
  buyerCompany,
} = menuConfigItems;

// const getDefaultMenu = () => ({
//   header: {
//     self: {},
//     items: [],
//   },
//   aside: {
//     self: {},
//     items: [vendorsTenders, catalogForUser, profile, blog],
//   },
// });

const getManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      users,
      // tariffs,
      // legal,
    ],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // orders,
      // productDashboard,
      // shifts,
      // products,
      users,
      tariffs,
      // settings,
      legal,
      // blog,
      // chats,
      // statistic,
    ],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      vendorProducts,
      // productDashboard,
      // vendorsTenders,
      buyersDocuments,
      vendorCompany,
      // blog,
      // chats,
      // statistic,
      vendorRequests,
    ],
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // buyersOrders,
      // buyersTenders,
      buyerCompany,
      catalogForUser,
      guestBlog,
      buyerRequests,
    ],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [
      // catalog,
      // guestBlog
    ],
  },
});

const getContentManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog, orders, users],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getGuestMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
    if (userRoles[0] === 'ROLE_MANAGER') {
      return getManagerMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER') {
      return getGuestMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR_STAFF') {
      return getGuestMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER_STAFF') {
      return getGuestMenu();
    }
    if (userRoles[0] === 'ROLE_CONTENT_MANAGER') {
      return getGuestMenu();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
