import React from 'react';

import homeStyles from '../../../../../constants/homeStyles';
import { useStylesEditPage } from '../hooks/useStyleEditPage';
import { useFormatMessage } from '../../../../../hooks';
import { UploadImages } from '../../../../../components/ui/UploadImages';
import { ButtonWithLoader } from '../../../../../components/other/Buttons';
import { IUser } from '../../../../../interfaces/user';

type TProps = {
  setTabValue: (value: number) => void;
  id?: string;
  user: IUser | null;
  uploadAvatar: (images: File[]) => void;
  deleteUserAvatar: (id: string) => void;
};

const FormPhoto: React.FC<TProps> = ({
  setTabValue,
  id,
  user,
  uploadAvatar,
  deleteUserAvatar,
}) => {
  const fm = useFormatMessage();
  const classes = useStylesEditPage();
  const homeClasses = homeStyles();

  return (
    <div className={homeClasses.classes.form}>
      <UploadImages
        images={
          user?.avatar
            ? [
                {
                  id: 1,
                  path: typeof user.avatar === 'string' ? user.avatar : user.avatar.path,
                  main: true,
                  name: 'avatar',
                  extension: '.jpg',
                  mime_type: typeof user.avatar === 'string' ? user.avatar : user.avatar.path,
                  small: '',
                  path_blob: '',
                  banner: false,
                },
              ]
            : undefined
        }
        uploadImages={uploadAvatar}
        withGallery
        withCrop
        cropAspect={1}
        deleteImage={() => id && deleteUserAvatar(id)}
        title={fm('ADD.PHOTO.VEHICLE')}
        hideDescription
      />

      <div className={classes.actions}>
        <ButtonWithLoader
          onPress={() => setTabValue(0)}
          type='button'
          variant='contained'
          color='secondary'
          marginRight={20}
        >
          {fm('COMMON.BUTTON.BACK')}
        </ButtonWithLoader>

        <ButtonWithLoader onPress={() => setTabValue(2)}>
          {fm('AUTH.MAIN.BUTTON')}
        </ButtonWithLoader>
      </div>
    </div>
  );
};

export default FormPhoto;
