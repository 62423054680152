import React from 'react';
import { Button, darken } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onClick?: () => {} | void;
}

const noop = () => {};

const RedOutlinedButton: React.FC<IProps> = ({
  children,
  type = 'button',
  onClick = noop,
}) => {

  return (
    <Button
      type={type}
      variant='outlined'
      color='inherit'
      onClick={onClick}
      sx={{
        color: '#ffffff',
        backgroundColor: '#b12a27',
        '&:hover': {
          backgroundColor: darken('#b12a27', 0.1),
        },
      }}
    >
      {children}
    </Button>
  );
};

export default RedOutlinedButton;
