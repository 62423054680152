import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Autocomplete, Box, TextField } from '@mui/material';

import NumberFormatCustom from '../../../../../components/formComponents/NumberFormatCustom';
import { ITypeParameter } from '../../../../../interfaces/productType';
import { useStylesHeaderAddMultiply } from '../../hooks/useStyles';
import { ICountry } from '../../../../auth/interfaces';

type TProps = {
  formik: any;
  countries: ICountry[];
  i: number;
};

const TypeParams: React.FC<TProps> = ({ formik, countries, i }) => {
  const classes1 = useStylesHeaderAddMultiply();
  const { classes } = classes1;
  const intl = useIntl();

  const filterCountries = useMemo(() => {
    if (countries) {
      const newList: ICountry[] = [];
      countries.forEach(i => {
        if (i.id === 1 || i.id === 76) {
          newList.push({ ...i, favorite: true });
        } else {
          newList.push(i);
        }
      });
      return newList;
    }
    return [];
  }, [countries]);

  const { values, handleBlur, handleChange, setFieldValue } = formik;
  return (
    <>
      {values.filteredParams.length > 0 && (
        <div className={classes.wrapperFields}>
          {values.filteredParams.map((item: ITypeParameter) => {
            if (item.type === 'number') {
              if (item.name === 'Year of manufacture') {
                return (
                  <TextField
                    type='text'
                    label={item.name}
                    color='secondary'
                    name={`parameter${item.id}_${i}`}
                    // @ts-ignore
                    value={values[`parameter${item.id}_${i}`] || ''}
                    variant='outlined'
                    onBlur={handleBlur(`parameter${item.id}_${i}`)}
                    onChange={handleChange(`parameter${item.id}_${i}`)}
                    key={item.id}
                    className={classes.param170}
                    InputProps={{
                      inputComponent:
                        item.name !== 'Year of manufacture'
                          ? (NumberFormatCustom as any)
                          : undefined,
                    }}
                  />
                );
              }
              return (
                <TextField
                  type='text'
                  label={item.name}
                  color='secondary'
                  name={`parameter${item.id}_${i}`}
                  // @ts-ignore
                  value={values[`parameter${item.id}_${i}`] || ''}
                  variant='outlined'
                  onBlur={handleBlur(`parameter${item.id}_${i}`)}
                  onChange={handleChange(`parameter${item.id}_${i}`)}
                  key={item.id}
                  className={classes.param170}
                  InputProps={{
                    inputComponent:
                      item.name !== 'Alkohol %Vol.' ? (NumberFormatCustom as any) : undefined,
                  }}
                />
              );
            }
            if (item.type === 'enum') {
              return (
                <>
                  <Autocomplete
                    options={
                      item.parameter_enum_values?.sort((a, b) =>
                        // eslint-disable-next-line no-nested-ternary
                        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                      ) || []
                    }
                    autoHighlight
                    className={classes.paramEnum}
                    value={item.parameter_enum_values?.find(
                      el =>
                        el.value?.toString() === values[`parameter${item.id}_${i}`]?.toString()
                    )}
                    onChange={(_event: any, newValue) => {
                      setFieldValue(`parameter${item.id}_${i}`, newValue?.value || '');
                    }}
                    groupBy={i =>
                      intl.formatMessage({
                        id: i.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                      })
                    }
                    noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                    loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                    getOptionLabel={option =>
                      option.value ? option.translations?.[intl.locale] || option.value : ''
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        value={option.value}
                      >
                        {option.translations?.[intl.locale] || option.value}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={item.names?.[intl.locale] || item.name}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          style: { fontFamily: 'Montserrat' },
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </>
              );
            }

            if (item.type === 'string') {
              return (
                <TextField
                  key={item.id}
                  type='text'
                  variant='outlined'
                  color='secondary'
                  label={item.name}
                  name={`parameter${item.id}_${i}`}
                  // @ts-ignore
                  value={values[`parameter${item.id}_${i}`] || ''}
                  onBlur={handleBlur(`parameter${item.id}_${i}`)}
                  onChange={handleChange(`parameter${item.id}_${i}`)}
                  className={classes.param170}
                />
              );
            }
            if (item.type === 'text') {
              return (
                <TextField
                  type='text'
                  label={item.name || ''}
                  color='secondary'
                  name={`parameter${item.id}_${i}`}
                  // @ts-ignore
                  value={values[`parameter${item.id}_${i}`] || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  key={item.id}
                  className={classes.paramText}
                />
              );
            }

            if (item.type === 'multiple_enum') {
              return (
                <>
                  <Autocomplete
                    options={
                      item.parameter_enum_values?.sort((a, b) =>
                        // eslint-disable-next-line no-nested-ternary
                        a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                      ) || []
                    }
                    multiple
                    autoHighlight
                    value={
                      values[`parameter${item.id}_${i}`]
                        ? values[`parameter${item.id}_${i}`].map((i: string) => {
                            const find = item.parameter_enum_values?.find(
                              el => el.value === i
                            );
                            return find;
                          })
                        : []
                    }
                    onChange={(_event: any, newValue) => {
                      newValue &&
                        setFieldValue(
                          `parameter${item.id}_${i}`,
                          newValue.map(i => i?.value)
                        );
                    }}
                    groupBy={i =>
                      intl.formatMessage({
                        id: i?.favorite ? 'POPULAR.VARIANTS' : 'OTHER.VARIANTS',
                      })
                    }
                    className={classes.paramEnum}
                    noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                    loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                    disableClearable
                    getOptionLabel={option =>
                      option.value ? option.translations?.[intl.locale] || option.value : ''
                    }
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                        value={option?.value || ''}
                      >
                        {option.translations?.[intl.locale] || option.value}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={item.names?.[intl.locale] || item.name}
                        variant='outlined'
                        inputProps={{
                          ...params.inputProps,
                          style: { fontFamily: 'Montserrat' },
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </>
              );
            }

            if (item.type === 'country') {
              return (
                <Autocomplete
                  id='country-select-demo'
                  options={filterCountries.sort((a, b) =>
                    // eslint-disable-next-line no-nested-ternary
                    a.favorite === b.favorite ? 0 : a.favorite ? -1 : 1
                  )}
                  autoHighlight
                  value={filterCountries.find(
                    el => el.name === values[`parameter${item.id}_${i}`]
                  )}
                  onChange={(_event: any, newValue) => {
                    newValue && setFieldValue(`parameter${item.id}_${i}`, newValue.name);
                  }}
                  groupBy={i => intl.formatMessage({ id: i.favorite ? 'FAVORITES' : 'OTHER' })}
                  noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
                  loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
                  disableClearable
                  className={classes.paramEnum}
                  getOptionLabel={option =>
                    intl.locale === 'ru' ? option.ru_name : option.en_name
                  }
                  renderOption={(props, option) => (
                    <Box
                      component='li'
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                      value={option.id}
                    >
                      {intl.locale === 'ru' ? option.ru_name : option.en_name} +{option.code}
                    </Box>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={item.names?.[intl.locale] || item.name}
                      variant='outlined'
                      inputProps={{
                        ...params.inputProps,
                        style: { fontFamily: 'Montserrat' },
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              );
            }
          })}
        </div>
      )}
    </>
  );
};

export default React.memo(TypeParams);
