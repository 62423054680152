import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { getResponseMessage } from '../../../../../utils/utils';
import { deleteUserPhoto } from '../../../../../crud/users.crud';

export const useDeletePhotoUser = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingAvatarDelete, setLoading] = useState(false);
  const [successAvatarDelete, setSuccess] = useState(false);
  const deleteUserAvatar = useCallback(async (id: string) => {
    setLoading(true);
    if (id) {
      deleteUserPhoto(id)
        .then(() => {
          setSuccess(true);
          // enqueueSnackbar(`${intl.formatMessage({ id: 'USER.PHOTO.DELETE' })}`, {
          //   variant: 'success',
          // });
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setSuccess(false);
          }, 200);
        });
    }
  }, []);
  return { deleteUserAvatar, loadingAvatarDelete, successAvatarDelete };
};
