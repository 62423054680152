import React, { useEffect, useState } from 'react';
import { TextField, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CellI } from '../../../interfaces';
import NumberFormatCustom from '../../../../../../components/formComponents/NumberFormatCustom';

const useStyles = makeStyles(() => {
  return {
    textField: {
      margin: 0,
      // width: 63,
      width: 100,
      height: 53,
      textAlign: 'center',
    },
  };
});

interface Props {
  cell: CellI;
  submit: (newValue: string | number | null) => void;
  loading: boolean;
  success: boolean;
  editable: boolean;
}

const EditableCell = ({ cell, submit, loading, success, editable }: Props) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(cell.value);

  useEffect(() => {
    setEdit(false);
  }, [success]);

  if (loading) return <CircularProgress color='inherit' size={30} />;

  return edit ? (
    <TextField
      autoFocus
      margin='normal'
      value={value}
      onChange={e => setValue(e.target.value)}
      className={classes.textField}
      onBlur={() => {
        if (value !== cell.value) {
          submit(value);
        } else {
          setEdit(false);
        }
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          if (value !== cell.value) {
            submit(value);
          } else {
            setEdit(false);
          }
        }
      }}
      variant='outlined'
      autoComplete='off'
      InputProps={{
        inputComponent: NumberFormatCustom as any,
      }}
    />
  ) : (
    <div
      onClick={() => {
        if (editable) {
          setEdit(true);
        }
      }}
    >
      {cell.text}
    </div>
  );
};

export default EditableCell;
