import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { Card, Tabs, Tab } from '@mui/material';
import { useIntl } from 'react-intl';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { Modal } from '../../../../components/other/Modals';
import { IAppState } from '../../../../store/rootDuck';
import { actions as productTypesActions } from '../../../../store/ducks/productType.duck';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { actions as usersActions } from '../../../../store/ducks/users.duck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { usersBreadCrumbs } from './utils/createBreadCrumbs';
import homeStyles from '../../../../constants/homeStyles';
import { useStylesEditPage } from './hooks/useStyleEditPage';
import {
  useDefineUserRole,
  useFormatMessage,
  useShowErrors,
  useShowSuccesses,
} from '../../../../hooks';
import { useFormikUserEdit } from './hooks/useFormikUserEdit';
// import { useSelectStyle } from '../../../../hooks/useSelectStyle';
import { handleUploadAvatar } from './hooks/handleUploadAvatar';
// import SelectField from '../../../../components/ui/SelectField';
import { useDeletePhotoUser } from './hooks/useDeleteUserPhoto';
import { UserListType } from '../constants';
import { getRole } from './utils';
import FormUser from './components/FormUser';
import FormPhoto from './components/FormPhoto';
import FormCompany from './components/FormCompany';
import { useEditCompany } from './hooks/useEditCompany';
import FormVehicle from './components/FormVehicle';
import { useEditProduct } from './hooks/useEditProduct';
import { useAxiosGetCallback } from '../../../../hooks/useAxiosGet';

const UsersEditPage: React.FC<TPropsFromRedux> = ({
  me,
  user,
  fetchMe,
  fetch,
  clearFetchUser,
  loading,
  clearEdit,
  add,
  edit,
  delUser,
  clearDelUser,
  delUserSuccess,
  delUserError,
  delUserLoading,
  editSuccess,
  editLoading,
  editError,
  // editCompany,
  editCompanySuccess,
  editCompanyError,
  editCompanyLoading,
  searchCompany,
  fetchCompanies,
  loadingSearchCompanies,
  companies,
  pageCompanies,
  perPageCompanies,
  totalCompanies,
  loadingCompanies,
  clearEditCompany,
  clearFetchCompanies,
  clearSearchCompany,
  byIdError,
  companiesErr,
  companiesSearchErr,
  clearPagination,
  addSuccess,
  addUserId,
  clearAddUserId,
  company,
  fetchCompany,
  categories,
  fetchCategories,
  clearParams,
}) => {
  const { userId, type } = useParams();
  const id = userId;
  const [open, setOpen] = useState(false);
  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const fm = useFormatMessage();
  const { data: countries, makeRequest } = useAxiosGetCallback<any>();
  const role = getRole(type as UserListType, true);
  // const selectStyle = useSelectStyle();
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isVendorStaff = useDefineUserRole(user, 'ROLE_VENDOR_STAFF');
  const isBuyerStaff = useDefineUserRole(user, 'ROLE_BUYER_STAFF');
  const isVendor = useDefineUserRole(user, 'ROLE_VENDOR');
  const isBuyer = useDefineUserRole(user, 'ROLE_BUYER');
  const [updateCompany, setUpdateCompany] = useState(false);
  const [updateVehicle, setUpdateVehicle] = useState(false);
  // const visibleBonuses = useMemo(() => {
  //   if (isVendorStaff || role === 'ROLE_VENDOR_STAFF') {
  //     if (me?.is_admin && tabValue === 3 && id) return 'flex';
  //     if (isRoleManager && tabValue === 1 && id) return 'flex';
  //   }
  //   if (me?.is_admin && tabValue === 4 && id) return 'flex';
  //   if (isRoleManager && tabValue === 2 && id) return 'flex';
  //   return 'none';
  // }, [me, tabValue, isRoleManager, isVendorStaff, role]);
  const visibleImages = useMemo(() => {
    if (isVendorStaff || role === 'ROLE_VENDOR_STAFF')
      return tabValue === 0 ? 'contents' : 'none';
    return tabValue === 1 ? 'contents' : 'none';
  }, [tabValue, isVendorStaff, role]);
  const classes = useStylesEditPage();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const intl = useIntl();
  const { deleteUserAvatar, successAvatarDelete } = useDeletePhotoUser();
  const { loadingCompany, successCompany, fetchCompany: editCompany } = useEditCompany();
  const { loadingProduct, successProduct, fetchProduct } = useEditProduct();

  const disabledRole = useMemo(() => {
    if (id) return true;
    if (me?.is_admin && !id && role === 'ROLE_MANAGER') return false;
    return (me?.is_admin || isRoleManager) &&
      (isVendorStaff || isBuyerStaff || isVendor || isBuyer) &&
      !!id
      ? false
      : !!id || !!role;
  }, [me, isRoleManager, isVendorStaff, isBuyerStaff, isVendor, isBuyer, id, role]);

  // useLayoutEffect(() => {
  //   if (me?.is_admin && user?.company?.id) {
  //     fetchCompany(user?.company?.id);
  //   }
  // }, [user, me]);

  setLayoutSubheader({
    title: id ? fm('USER.EDIT.TITLE') : fm('USER.ADD.TITLE'),
    breadcrumb: usersBreadCrumbs(intl, type || '', clearPagination),
  });
  setLayoutFooter({ show: true });

  const [uploadAvatar, loadingAvatar, errAvatar] = handleUploadAvatar(id || 0);

  useEffect(() => {
    makeRequest('/api/phone_codes');
    fetchMe();
  }, []);

  useEffect(() => {
    if (id) fetch(Number(id));
  }, [id]);

  // formik
  const formik = useFormikUserEdit(id, user, edit, add, role, me);

  const { values, handleSubmit } = formik;

  // const deleteCompany = useCallback(() => {
  //   if (!company) return;
  //   if (!user?.company) return;
  //   if (id) {
  //     const ids = company.managers.map(item => item.id).filter(id => id !== user.id);
  //     editCompany({ id: Number(user?.company?.id), data: { managers_ids: ids } });
  //   } else {
  //     setFieldValue('company', undefined);
  //   }
  // }, [userId, user?.company, company, id]);

  // const connectCompany = useCallback(
  //   (userId2: number, company: ICompany) => {
  //     if (id) {
  //       editCompany({
  //         id: company.id,
  //         data: { managers_ids: [...company.managers.map(i => i.id), userId2] },
  //       });
  //     } else {
  //       setFieldValue('company', company);
  //       setOpenDialogCompanies(false);
  //     }
  //   },
  //   [id]
  // );

  // const openSearchCompaniesDialog = useCallback(() => {
  //   if (values.companyInn === '') return null;
  //   searchCompany({ page: 1, perPage: 20, inn: values.companyInn });
  //   setOpenDialogCompanies(true);
  // }, [values]);

  // const openCompaniesDialog = useCallback(() => {
  //   fetchCompanies({ perPage: 20, page: pageCompanies });
  //   setOpenDialogCompanies(true);
  // }, [values]);

  // after success
  useEffect(() => {
    if (addSuccess && addUserId) {
      const userType =
        // eslint-disable-next-line no-nested-ternary
        values.role === 'ROLE_VENDOR'
          ? UserListType.VENDOR
          : // eslint-disable-next-line no-nested-ternary
          values.role === 'ROLE_BUYER'
          ? UserListType.BUYER
          : values.role === 'ROLE_MANAGER'
          ? UserListType.MANAGER
          : null;
      userType && navigate(`/users/edit/${userType}/${addUserId}`, { replace: true });
      clearAddUserId();
    } else if (delUserSuccess) {
      navigate(-1);
    } else if (editSuccess || successCompany || successProduct) {
      id && fetch(Number(id));
    }
  }, [
    delUserSuccess,
    editSuccess,
    navigate,
    addSuccess,
    addUserId,
    successCompany,
    successProduct,
  ]);

  useEffect(() => {
    editError && tabValue === 1 && setTabValue(0);
  }, [editError]);

  const handleChangeTabPhoto = useCallback(
    (newValue: number) => {
      handleSubmit();
      if (values.role === 'ROLE_VENDOR' && values.phoneNumbers) setTabValue(newValue);
      if (
        values.role !== 'ROLE_VENDOR' &&
        values.login &&
        values.email &&
        values.password &&
        values.password2 &&
        values.password === values.password2 &&
        values.role
      ) {
        setTabValue(newValue);
      }
    },
    [handleSubmit, values, setTabValue, id]
  );

  useEffect(() => {
    // if (editCompanySuccess) {
    //   setOpenDialogCompanies(false);
    //   fetch(Number(id));
    // }
    if (successAvatarDelete) {
      fetch(Number(id));
    }
  }, [editCompanySuccess, editCompanyError, id, successAvatarDelete]);

  // notifications
  useShowErrors([
    byIdError,
    delUserError,
    editError,
    companiesErr,
    companiesSearchErr,
    editCompanyError,
    errAvatar && fm('USER.ERROR.PHOTO'),
  ]);
  // useShowSuccesses([{ when: editSuccess, msg: fm('TITLE.SAVED') }]);

  useEffect(() => {
    if (byIdError) {
      navigate(-1);
      clearFetchUser();
    }
  }, [byIdError]);

  // clean up
  useEffect(() => {
    return () => {
      clearParams();
      clearFetchUser();
      clearDelUser();
      clearEdit();
      clearFetchCompanies();
      clearSearchCompany();
      clearEditCompany();
    };
  }, []);

  // if (loading) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <Card className={homeClasses.classes.container}>
            <Tabs
              value={tabValue}
              onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
                if (id && values.role === 'ROLE_VENDOR') {
                  tabValue === 0 && handleSubmit();
                  tabValue === 2 && setUpdateCompany(true);
                  tabValue === 3 && setUpdateVehicle(true);
                }
                if (newValue !== 0 && !id) {
                  handleChangeTabPhoto(newValue);
                } else {
                  setTabValue(newValue);
                }
              }}
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label={fm('PRODUCT.TABS.COMMON')} />
              <Tab label={fm('SETTING.PROFILE.PHOTO')} />
              {values.role === 'ROLE_VENDOR' && <Tab label={fm('USER.TABLE.COMPANY')} />}
              {values.role === 'ROLE_VENDOR' && <Tab label={fm('VEHICLE')} />}
            </Tabs>
            <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
              <FormUser
                user={user}
                formik={formik}
                disabledRole={disabledRole}
                role={role}
                id={id}
                setOpen={setOpen}
                editLoading={editLoading || editCompanyLoading || loadingCompany}
                handleSubmit={() => {
                  if (values.role !== 'ROLE_VENDOR') {
                    handleSubmit();
                  } else if (id) {
                    handleSubmit();
                    setTabValue(1);
                  } else {
                    handleChangeTabPhoto(1);
                  }
                }}
              />
            </div>

            <div
              style={{
                display: visibleImages,
                width: '100%',
              }}
            >
              <FormPhoto
                setTabValue={setTabValue}
                id={id}
                user={user}
                uploadAvatar={image => uploadAvatar(image, user?.id)}
                deleteUserAvatar={deleteUserAvatar}
              />
            </div>
            <div style={{ display: tabValue === 2 ? 'contents' : 'none' }}>
              <FormCompany
                user={user}
                loading={loadingCompany}
                editCompany={editCompany}
                setTabValue={setTabValue}
                updateCompany={updateCompany}
                setUpdateCompany={setUpdateCompany}
              />
            </div>

            <div style={{ display: tabValue === 3 ? 'contents' : 'none' }}>
              <FormVehicle
                user={user}
                loading={loadingProduct}
                editProduct={fetchProduct}
                setTabValue={setTabValue}
                countries={countries}
                updateVehicle={updateVehicle}
                setUpdateVehicle={setUpdateVehicle}
              />
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openDialogCompanies}
        onClose={editCompanyLoading ? () => {} : () => setOpenDialogCompanies(false)}
        title={fm('COMPANY.EDIT.MOUNT_TITLE')}
        loading={loadingCompanies || loadingSearchCompanies || editCompanyLoading}
        content={
          <CompaniesTable
            me={user as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            onConnectUser={company => {
              connectCompany(user?.id || 0, company);
            }}
            companies={companies}
            fetch={({ page, perPage }) => fetchCompanies({ page, perPage })}
          />
        }
        actions={[
          { title: fm('COMMON.BUTTON.CLOSE'), onClick: () => setOpenDialogCompanies(false) },
        ]}
      /> */}

      <Modal
        open={open}
        onClose={delUserLoading ? () => {} : () => setOpen(false)}
        title={fm('USER.DELETE.TITLE')}
        loading={delUserLoading}
        content={delUserLoading ? undefined : fm('USER.DELETE.TEXT')}
        actions={
          delUserLoading
            ? undefined
            : [
                {
                  title: fm('COMMON.BUTTON.CANCEL'),
                  onClick: () => setOpen(false),
                },
                {
                  title: fm('COMMON.BUTTON.DELETE'),
                  onClick: () => id && delUser(+id),
                },
              ]
        }
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    user: state.users.user,
    loading: state.users.byIdLoading,
    editSuccess: state.users.editSuccess,
    editLoading: state.users.editLoading,
    editError: state.users.editError,
    byIdSuccess: state.users.byIdSuccess,
    byIdError: state.users.byIdError,
    delUserLoading: state.users.delUserLoading,
    delUserSuccess: state.users.delUserSuccess,
    delUserError: state.users.delUserError,

    editCompanySuccess: state.companies.editSuccess,
    editCompanyLoading: state.companies.editLoading,
    editCompanyError: state.companies.editError,
    pageCompanies: state.companies.page,
    perPageCompanies: state.companies.per_page,
    totalCompanies: state.companies.total,
    companies: state.companies.companies,
    companiesErr: state.companies.error,
    loadingSearchCompanies: state.companies.searchLoading,
    loadingCompanies: state.companies.loading,
    companiesSearchErr: state.companies.searchError,
    addSuccess: state.users.addSuccess,
    addUserId: state.users.addUserId,
    company: state.companies.company,

    categories: state.categories.categories,
  }),
  {
    fetch: usersActions.fetchByIdRequest,
    clearFetchUser: usersActions.clearFetchById,
    clearEdit: usersActions.clearEdit,
    clearDelUser: usersActions.clearDelUser,
    add: usersActions.addRequest,
    edit: usersActions.editRequest,

    delUser: usersActions.delUserRequest,

    fetchMe: profileActions.fetchRequest,

    clearCompanies: companiesActions.clearCompanies,
    editCompany: companiesActions.editRequest,
    clearEditCompany: companiesActions.clearEdit,

    fetchCompanies: companiesActions.fetchRequest,
    clearFetchCompanies: companiesActions.clearFetch,

    searchCompany: companiesActions.searchRequest,
    clearSearchCompany: companiesActions.clearSearch,
    clearPagination: usersActions.clearPagination,
    clearAddUserId: usersActions.clearAddUserId,
    fetchCompany: companiesActions.fetchByIdRequest,

    fetchCategories: categoriesActions.fetchFullRequest,
    clearParams: productTypesActions.clearParams,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersEditPage);
