import React, { useEffect, useState } from 'react';
import { InputLabel, TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { IUser } from '../../../../../interfaces/user';
import homeStyles from '../../../../../constants/homeStyles';
import { useStylesEditPage } from '../hooks/useStyleEditPage';
import { useFormatMessage } from '../../../../../hooks';
import { ICompany, ICompanyEditProps } from '../../../../../interfaces/companies';
import CkEditorCustom from '../../../../../components/formComponents/CkEditorCustom';

interface IField<T> {
  title: string;
  field: T;
}

interface TProps {
  user: IUser | null;
  loading: boolean;
  editCompany: (id: number, data: ICompanyEditProps) => void;
  setTabValue: (value: number) => void;
  updateCompany: boolean;
  setUpdateCompany: (value: boolean) => void;
}

const getInitialValues = (company: ICompany | null) => ({
  name: company?.name || '',
  inn: company?.inn || '',
  phone_number: company?.phone_number || '',
});

const FormCompany: React.FC<TProps> = ({
  user,
  loading,
  editCompany,
  setTabValue,
  updateCompany,
  setUpdateCompany,
}) => {
  const fm = useFormatMessage();

  const classes = useStylesEditPage();
  const homeClasses = homeStyles();
  const intl = useIntl();

  const [editorStatePayment, setEditorStatePayments] = useState<any>(null);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: getInitialValues(user?.company || null),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),
    onSubmit: submitValues => {
      let paymentDetails = '';
      if (editorStatePayment) {
        paymentDetails = editorStatePayment?.getData() || '';
      }
      user?.company?.id &&
        editCompany(user.company.id, { ...submitValues, payment_details: paymentDetails });
    },
  });

  useEffect(() => {
    if (updateCompany) {
      handleSubmit();
      setUpdateCompany(false);
    }
  }, [updateCompany]);

  const FIELDS: IField<keyof typeof values>[] = [
    { title: intl.formatMessage({ id: 'COMPANY.FORM.LEGAL_PERSON' }), field: 'name' },
    { title: intl.formatMessage({ id: 'COMPANY.FORM.INN' }), field: 'inn' },
    { title: intl.formatMessage({ id: 'COMPANY.FORM.PHONE' }), field: 'phone_number' },
  ];

  return (
    <div className={homeClasses.classes.form}>
      {FIELDS.map(item => (
        <TextField
          key={item.field}
          type='text'
          label={item.title}
          margin='normal'
          name={item.field}
          value={values[item.field]}
          variant='outlined'
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={touched[item.field] && errors[item.field]}
          error={Boolean(touched[item.field] && errors[item.field])}
        />
      ))}
      <div style={{ marginTop: 10 }}>
        <InputLabel shrink>{intl.formatMessage({ id: 'PRODUCT.TABLE.PAYMENT' })}</InputLabel>
        <CkEditorCustom
          html={user?.company?.payment_details || ''}
          setEditorStateCallback={setEditorStatePayments}
        />
      </div>
      <div className={classes.actions}>
        <ButtonWithLoader
          onPress={() => {
            handleSubmit();
            setTabValue(1);
          }}
          type='button'
          variant='contained'
          color='secondary'
          disabled={loading}
        >
          {fm('COMMON.BUTTON.BACK')}
        </ButtonWithLoader>

        <ButtonWithLoader
          marginLeft={20}
          onPress={() => {
            handleSubmit();
            setTabValue(3);
          }}
          disabled={loading}
        >
          {fm('AUTH.MAIN.BUTTON')}
        </ButtonWithLoader>
      </div>
    </div>
  );
};

export default FormCompany;
