// компонент для отображения и управления картинками онлайн,
// картинки подружаются из url с сервера, функции setMainImage и deleteImage
// отправляют запросы и данные изменяются на сервере,
// используется для редактирования продуктов и тд
import React from 'react';

import { useIntl } from 'react-intl';
import FilesDropzone from '../../formComponents/FilesDropzone';
import { MainImg } from './components/MainImg';
import { useStyles } from './hooks/useStyles';
import { ImagesGallery } from './components/ImagesGallery';
import { IPhoto } from '../../../interfaces/photo';
import { IUser } from '../../../interfaces/user';

export type TUrl = string;

interface IProps {
  images: IPhoto[] | undefined;
  uploadImages: (files: File[]) => void;
  withGallery?: boolean;
  withCrop?: boolean;
  setMainImage?: (id: IPhoto['id']) => void;
  deleteImage?: (id: IPhoto['id']) => void;
  setBannerImage?: (id: IPhoto['id']) => void;
  avaliableNumberOfFilesToUpload?: number;
  cropAspect?: number;
  containerStyle?: React.CSSProperties;
  me?: IUser | undefined;
  title?: string;
  hideDescription?: boolean;
}

export const UploadImages: React.FC<IProps> = ({
  images,
  uploadImages,
  withGallery = false,
  withCrop = false,
  setMainImage,
  deleteImage,
  avaliableNumberOfFilesToUpload = 1,
  cropAspect,
  containerStyle,
  me,
  setBannerImage,
  title,
  hideDescription,
}) => {
  const intl = useIntl();

  const classes = useStyles();
  return (
    <div className={classes.container} style={containerStyle}>
      <MainImg images={images} deleteImage={deleteImage} />
      {withGallery && (
        <ImagesGallery
          me={me}
          images={images}
          setMainImage={setMainImage}
          deleteImage={deleteImage}
          setBannerImage={setBannerImage}
        />
      )}
      <FilesDropzone
        cropAspect={cropAspect}
        uploadFiles={uploadImages}
        avaliableNumberOfFilesToUpload={avaliableNumberOfFilesToUpload}
        title={title || intl.formatMessage({ id: 'COMMON.PHOTO.LOAD' })}
        description='Максимальный размер файла: 1 МБ'
        withCrop={withCrop}
      />
    </div>
  );
};
