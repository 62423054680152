import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../../utils/utils';
import { useFormatMessage } from '../../../../../hooks';
import { editProduct } from '../../../../../crud/product.crud';

export const useEditProduct = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingProduct, setLoading] = useState(false);
  const [successProduct, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchProduct = useCallback(async (id: number, data: FormData) => {
    setLoading(true);
    editProduct(id, data)
      .then(() => {
        setSuccess(true);
        // enqueueSnackbar(fm('VEHICLE.EDIT.SAVE'), { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 200);
      });
  }, []);

  return { fetchProduct, successProduct, loadingProduct };
};
