import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Reducer } from 'redux';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import { put, takeLatest, call } from 'redux-saga/effects';

import { TAppActions } from '../rootDuck';

import { ActionsUnion, createAction } from '../../utils/action-helper';
import { IServerResponse } from '../../interfaces/server';
import { IEnumValue, IProductType, ITypeParameter } from '../../interfaces/productType';
import {
  getProductTypes,
  getProductType,
  createType,
  editType,
  addParameterById,
  editParameterById,
  getParametersById,
  deleteProductType,
  setParamsPositions,
} from '../../crud/productType.crud';
import { getResponseMessage } from '../../utils/utils';
import { addEnumValue, addParameterImages, editEnumValue } from '../../crud/product.crud';

const FETCH_REQUEST = 'productType/FETCH_REQUEST';
const FETCH_SUCCESS = 'productType/FETCH_SUCCESS';
const FETCH_FAIL = 'productType/FETCH_FAIL';

const FETCH_BY_ID_REQUEST = 'productType/FETCH_BY_ID_REQUEST';
const FETCH_BY_ID_SUCCESS = 'productType/FETCH_BY_ID_SUCCESS';
const FETCH_BY_ID_FAIL = 'productType/FETCH_BY_ID_FAIL';

const FETCH_PARAMETERS_REQUEST = 'productType/FETCH_PARAMETERS_REQUEST';
const FETCH_PARAMETERS_SUCCESS = 'productType/FETCH_PARAMETERS_SUCCESS';
const FETCH_PARAMETERS_FAL = 'productType/FETCH_PARAMETERS_FAIL';

const ADD_PARAMETER_REQUEST = 'productType/ADD_PARAMETER_REQUEST';
const EDIT_PARAMETER_REQUEST = 'productType/EDIT_PARAMETER_REQUEST';
const EDIT_PARAMETER_SUCCESS = 'productType/EDIT_PARAMETER_SUCCESS';
const EDIT_PARAMETER_FAIL = 'productType/EDIT_PARAMETER_FAIL';

const CLEAR_EDIT = 'productType/CLEAR_EDIT';
const ADD_REQUEST = 'productType/ADD_REQUEST';
const EDIT_REQUEST = 'productType/EDIT_REQUEST';
const EDIT_SUCCESS = 'productType/EDIT_SUCCESS';
const EDIT_FAIL = 'productType/EDIT_FAIL';

const DEL_REQUEST = 'productType/DEL_REQUEST';
const DEL_FAIL = 'productType/DEL_FAIL';
const CLEAR_DEL = 'productType/CLEAR_DEL';
const CLEAR_PARAMETERS = 'productType/CLEAR_PARAMETERS';

const SET_POSITIONS_REQUEST = 'productType/SET_POSITIONS_REQUEST';
const SET_POSITIONS_SUCCESS = 'productType/SET_POSITIONS_SUCCESS';
const SET_POSITIONS_FAIL = 'productType/SET_POSITIONS_FAIL';
const SET_POSITIONS_CLEAR = 'productType/SET_POSITIONS_CLEAR';

export interface IInitialState {
  page: number;
  per_page: number;
  total: number;
  productTypes: IProductType[] | undefined;
  loading: boolean;
  success: boolean;
  error: string | null;

  productType: IProductType | undefined;
  byIdLoading: boolean;
  byIdSuccess: boolean;
  byIdError: string | null;

  typeParams: ITypeParameter[] | undefined;
  paramsLoading: boolean;
  paramsError: string | null;

  editLoading: boolean;
  editSuccess: boolean;
  editError: string | null;

  editParameterSuccess: boolean;
  editParameterError: string | null;

  delError: string | null;
  fetchTypeId: number | undefined;

  setPositionError: string | null;
  setPositionLoading: boolean;
  setPositionSuccess: boolean;
}

const initialState: IInitialState = {
  page: 1,
  per_page: 20,
  total: 0,
  productTypes: undefined,
  loading: false,
  success: false,
  error: null,

  productType: undefined,
  byIdLoading: false,
  byIdSuccess: false,
  byIdError: null,

  typeParams: undefined,
  paramsLoading: false,
  paramsError: null,

  editLoading: false,
  editSuccess: false,
  editError: null,

  editParameterSuccess: false,
  editParameterError: null,

  delError: null,
  fetchTypeId: undefined,

  setPositionError: null,
  setPositionLoading: false,
  setPositionSuccess: false,
};

export const reducer: Reducer<IInitialState & PersistPartial, TAppActions> = persistReducer(
  { storage, key: 'productTypes', whitelist: ['user', 'authToken'] },
  (state = initialState, action) => {
    switch (action.type) {
      case FETCH_REQUEST: {
        return {
          ...state,
          productTypes: undefined,
          loading: true,
          success: false,
          error: null,
        };
      }

      case FETCH_SUCCESS: {
        return {
          ...state,
          page: action.payload.page,
          per_page: action.payload.per_page,
          total: action.payload.total,
          productTypes: action.payload.data,
          loading: false,
          success: true,
        };
      }

      case FETCH_FAIL: {
        return { ...state, loading: false, error: action.payload };
      }

      case FETCH_BY_ID_REQUEST: {
        return {
          ...state,
          productType: undefined,
          byIdLoading: true,
          byIdSuccess: false,
          byIdError: null,
        };
      }

      case FETCH_BY_ID_SUCCESS: {
        return {
          ...state,
          productType: action.payload.data,
          byIdLoading: false,
          byIdSuccess: true,
        };
      }

      case FETCH_BY_ID_FAIL: {
        return { ...state, byIdLoading: false, byIdError: action.payload };
      }

      case FETCH_PARAMETERS_REQUEST: {
        return {
          ...state,
          // typeParams: undefined,
          paramsLoading: true,
          fetchTypeId: action.payload.id,
        };
      }
      case FETCH_PARAMETERS_SUCCESS: {
        return {
          ...state,
          paramsLoading: false,
          typeParams: action.payload,
        };
      }
      case FETCH_PARAMETERS_FAL: {
        return {
          ...state,
          paramsError: action.payload,
          paramsLoading: false,
        };
      }
      case CLEAR_EDIT: {
        return {
          ...state,
          editLoading: false,
          editSuccess: false,
          editError: null,
          editParameterError: null,
          editParameterSuccess: false,
          paramsLoading: false,
        };
      }

      case CLEAR_PARAMETERS: {
        return {
          ...state,
          typeParams: undefined,
          fetchTypeId: undefined,
        };
      }

      case ADD_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_REQUEST: {
        return { ...state, editLoading: true, editSuccess: false, editError: null };
      }

      case EDIT_SUCCESS: {
        return {
          ...state,
          editLoading: false,
          editSuccess: true,
          productType: action.payload,
        };
      }

      case EDIT_FAIL: {
        return { ...state, editLoading: false, editError: action.payload };
      }

      case EDIT_PARAMETER_SUCCESS: {
        return { ...state, editParameterSuccess: true };
      }

      case EDIT_PARAMETER_FAIL: {
        return { ...state, editParameterError: action.payload };
      }

      case DEL_FAIL: {
        return { ...state, delError: action.payload };
      }

      case SET_POSITIONS_REQUEST: {
        return {
          ...state,
          setPositionLoading: true,
          setPositionSuccess: false,
          setPositionError: null,
        };
      }
      case SET_POSITIONS_SUCCESS: {
        return {
          ...state,
          setPositionLoading: false,
          setPositionSuccess: true,
          setPositionError: null,
        };
      }
      case SET_POSITIONS_FAIL: {
        return {
          ...state,
          setPositionError: action.payload,
          setPositionLoading: false,
          setPositionSuccess: false,
        };
      }

      case SET_POSITIONS_CLEAR: {
        return {
          ...state,
          setPositionError: null,
          setPositionLoading: false,
          setPositionSuccess: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchRequest: () => createAction(FETCH_REQUEST),
  fetchSuccess: (payload: IServerResponse<IProductType[]>) =>
    createAction(FETCH_SUCCESS, payload),
  fetchFail: (payload: string) => createAction(FETCH_FAIL, payload),

  fetchByIdRequest: (payload: number) => createAction(FETCH_BY_ID_REQUEST, payload),
  fetchByIdSuccess: (payload: IServerResponse<IProductType>) =>
    createAction(FETCH_BY_ID_SUCCESS, payload),
  fetchByIdFail: (payload: string) => createAction(FETCH_BY_ID_FAIL, payload),

  clearEdit: () => createAction(CLEAR_EDIT),
  addRequest: (payload: { data: IProductType }) => createAction(ADD_REQUEST, payload),
  editRequest: (payload: { id: number; data: IProductType }) =>
    createAction(EDIT_REQUEST, payload),
  editSuccess: (payload: IProductType) => createAction(EDIT_SUCCESS, payload),
  editFail: (payload: string) => createAction(EDIT_FAIL, payload),

  addParameterRequest: (payload: {
    typeId: number;
    data: ITypeParameter;
    editEnums?: IEnumValue[];
    newEnums?: IEnumValue[];
  }) => createAction(ADD_PARAMETER_REQUEST, payload),
  editParameterRequest: (payload: {
    parameterId: number;
    data: ITypeParameter;
    editEnums?: IEnumValue[];
    newEnums?: IEnumValue[];
  }) => createAction(EDIT_PARAMETER_REQUEST, payload),
  editParameterSuccess: () => createAction(EDIT_PARAMETER_SUCCESS),
  editParameterFail: (payload: string) => createAction(EDIT_PARAMETER_FAIL, payload),

  fetchParamsByIdRequest: (payload: { id: number; filters?: boolean }) =>
    createAction(FETCH_PARAMETERS_REQUEST, payload),
  fetchParamsByIdSuccess: (payload: ITypeParameter[]) =>
    createAction(FETCH_PARAMETERS_SUCCESS, payload),
  fetchParamsByIdFail: (payload: string) => createAction(FETCH_PARAMETERS_FAL, payload),

  delRequest: (payload: { id: number }) => createAction(DEL_REQUEST, payload),
  delFail: (payload: string) => createAction(DEL_FAIL, payload),
  clearDel: () => createAction(CLEAR_DEL),
  clearParams: () => createAction(CLEAR_PARAMETERS),

  setPositionsRequest: (payload: { positions: string; id: number }) =>
    createAction(SET_POSITIONS_REQUEST, payload),
  setPositionsSuccess: () => createAction(SET_POSITIONS_SUCCESS),
  setPositionsFail: (payload: string) => createAction(SET_POSITIONS_FAIL, payload),
  setPositionsClear: () => createAction(SET_POSITIONS_CLEAR),
};

export type TActions = ActionsUnion<typeof actions>;

function* fetchSaga() {
  try {
    const { data }: { data: IServerResponse<IProductType[]> } = yield call(() =>
      getProductTypes()
    );
    yield put(actions.fetchSuccess(data));
  } catch (e) {
    yield put(actions.fetchFail(getResponseMessage(e)));
  }
}

function* fetchByIdSaga({ payload }: { payload: number }) {
  try {
    const { data }: { data: IServerResponse<IProductType> } = yield call(() =>
      getProductType(payload)
    );
    yield put(actions.fetchByIdSuccess(data));
  } catch (e) {
    yield put(actions.fetchByIdFail(getResponseMessage(e)));
  }
}

function* addSaga({ payload }: { payload: { data: IProductType } }) {
  try {
    const { data }: { data: IServerResponse<IProductType> } = yield call(() =>
      createType(payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* editSaga({ payload }: { payload: { id: number; data: IProductType } }) {
  try {
    const { data }: { data: IServerResponse<IProductType> } = yield call(() =>
      editType(payload.id, payload.data)
    );
    yield put(actions.editSuccess(data.data));
  } catch (e) {
    yield put(actions.editFail(getResponseMessage(e)));
  }
}

function* addParameterSaga({
  payload,
}: {
  payload: {
    typeId: number;
    data: ITypeParameter;
    editEnums?: IEnumValue[];
    newEnums?: IEnumValue[];
  };
}) {
  try {
    const { data }: { data: IServerResponse<ITypeParameter> } = yield call(() =>
      addParameterById(payload.typeId, payload.data)
    );
    if (
      data.data.id &&
      (payload.data.type === 'multiple_enum' || payload.data.type === 'enum') &&
      payload.newEnums
    ) {
      for (const newEnum of payload.newEnums) {
        if (newEnum.value) {
          const { data: createData }: { data: IServerResponse<ITypeParameter> } = yield call(
            () =>
              addEnumValue(data.data.id!, {
                value: newEnum.value,
                favorite: newEnum.favorite,
                translations: newEnum.translations || {},
              })
          );
          const typeParam = createData.data.parameter_enum_values?.find(
            i => i.value === newEnum.value
          );
          if (newEnum.image && typeParam?.id) {
            const formData = new FormData();
            formData.append('image', newEnum.image);
            yield call(() => addParameterImages(typeParam.id, formData));
          }
        }
      }
    }
    yield put(actions.editParameterSuccess());
  } catch (e) {
    yield put(actions.editParameterFail(getResponseMessage(e)));
  }
}

function* editParameterSaga({
  payload,
}: {
  payload: {
    parameterId: number;
    data: ITypeParameter;
    editEnums?: IEnumValue[];
    newEnums?: IEnumValue[];
  };
}) {
  try {
    yield call(() => editParameterById(payload.parameterId, payload.data));

    if (
      payload.parameterId &&
      (payload.data.type === 'multiple_enum' || payload.data.type === 'enum') &&
      payload.newEnums
    ) {
      if (payload.newEnums) {
        for (const newEnum of payload.newEnums) {
          if (newEnum.value) {
            const { data }: { data: IServerResponse<ITypeParameter> } = yield call(() =>
              addEnumValue(payload.parameterId, {
                value: newEnum.value,
                favorite: newEnum.favorite,
                translations: newEnum.translations || {},
              })
            );
            const typeParam = data.data.parameter_enum_values?.find(
              i => i.value === newEnum.value
            );
            if (newEnum.image && typeParam?.id) {
              const formData = new FormData();
              formData.append('image', newEnum.image);
              yield call(() => addParameterImages(typeParam?.id, formData));
            }
          }
        }
      }
      if (payload.editEnums) {
        for (const newEnum of payload.editEnums) {
          yield call(() =>
            editEnumValue(newEnum.id, {
              value: newEnum.value,
              favorite: newEnum.favorite,
              translations: newEnum.translations || {},
            })
          );

          if (newEnum.image && typeof newEnum.image !== 'string' && newEnum.id) {
            const formData = new FormData();
            formData.append('image', newEnum.image);
            yield call(() => addParameterImages(newEnum.id, formData));
          }
        }
      }
    }

    yield put(actions.editParameterSuccess());
  } catch (e) {
    yield put(actions.editParameterFail(getResponseMessage(e)));
  }
}

function* fetchParamsSaga({ payload }: { payload: { id: number; filters?: boolean } }) {
  try {
    const { data }: { data: IServerResponse<ITypeParameter[]> } = yield call(() =>
      getParametersById(payload.id)
    );
    yield put(actions.fetchParamsByIdSuccess(data.data));
  } catch (e) {
    yield put(actions.fetchParamsByIdFail(getResponseMessage(e)));
  }
}

function* delSaga({ payload }: { payload: { id: number } }) {
  try {
    yield call(() => deleteProductType(payload.id));
    yield put(actions.fetchRequest());
  } catch (e) {
    yield put(actions.delFail(getResponseMessage(e)));
  }
}

function* setPositionsSaga({ payload }: { payload: { positions: string; id: number } }) {
  try {
    yield call(() => setParamsPositions(payload.positions));
    const { data }: { data: IServerResponse<IProductType> } = yield call(() =>
      getProductType(payload.id)
    );
    yield put(actions.fetchByIdSuccess(data));
    yield put(actions.setPositionsSuccess());
  } catch (e) {
    yield put(actions.setPositionsFail(e));
  }
}

export function* saga() {
  yield takeLatest<ReturnType<typeof actions.fetchRequest>>(FETCH_REQUEST, fetchSaga);
  yield takeLatest<ReturnType<typeof actions.fetchByIdRequest>>(
    FETCH_BY_ID_REQUEST,
    fetchByIdSaga
  );
  yield takeLatest<ReturnType<typeof actions.addRequest>>(ADD_REQUEST, addSaga);
  yield takeLatest<ReturnType<typeof actions.editRequest>>(EDIT_REQUEST, editSaga);
  yield takeLatest<ReturnType<typeof actions.addParameterRequest>>(
    ADD_PARAMETER_REQUEST,
    addParameterSaga
  );
  yield takeLatest<ReturnType<typeof actions.editParameterRequest>>(
    EDIT_PARAMETER_REQUEST,
    editParameterSaga
  );
  yield takeLatest<ReturnType<typeof actions.fetchParamsByIdRequest>>(
    FETCH_PARAMETERS_REQUEST,
    fetchParamsSaga
  );
  yield takeLatest<ReturnType<typeof actions.setPositionsRequest>>(
    SET_POSITIONS_REQUEST,
    setPositionsSaga
  );
  yield takeLatest<ReturnType<typeof actions.delRequest>>(DEL_REQUEST, delSaga);
}
