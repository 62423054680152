import React, { useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ITariff } from '../../../../../interfaces/tariff';

import { TariffsType } from '../../TariffList';
import { TariffEditFields } from '../../../../../store/ducks/tariff.duck';
import TopTableCell from '../../../../../components/other/Table/TopTableCell';
import { useFormatMessage } from '../../../../../hooks';
import EditableCellTariff from '../EditableCellTariff';

const useStyles = makeStyles((theme: any) => ({
  table: {
    flex: 1,
    backgroundColor: '#fff',
    overflowX: 'auto',
  },
  blackCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.4)',
  },
  grayCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  titleCell: {
    // color: '#fff',
    width: '300px',
  },
  freeCell: {
    width: '200px',
    backgroundColor: '#F7F5F0',
  },
  proCell: {
    backgroundColor: theme.palette.success.dark,
    color: '#fff',
  },
  tariffCell: {
    width: '200px',
  },
  button: {
    width: '100%',
  },
  freeButtonCell: {
    width: '200px',
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  buyerProCell: {
    width: '200px',
    backgroundColor: theme.palette.secondary.main,
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
    color: '#00000',
  },
}));

const TariffTable: React.FC<{
  type: TariffsType;
  editable: boolean;
  tariffs: ITariff[];
  loading: boolean;
  success: boolean;
  editTariff: (
    id: number,
    forRole: string,
    name: string,
    field: TariffEditFields,
    value: string | number | null
  ) => void;
  submit: (id: number, field: TariffEditFields, value: string | number | null) => void;
  setModal: (value: boolean) => void;
  canPay: boolean;
  disablePay: boolean;
}> = ({
  type,
  tariffs,
  loading,
  success,
  submit,
  editTariff,
  editable,
  setModal,
  canPay,
  disablePay,
}) => {
  const fm = useFormatMessage();
  const classes = useStyles();
  const baseTarriff = useMemo(() => tariffs.find(i => i.id === 5), [tariffs]);
  const proTarriff = useMemo(() => tariffs.find(i => i.id === 6), [tariffs]);
  const proClass =
    type === TariffsType.buyers ? classes.buyerProCell : classes.providerProCell;

  if (!baseTarriff || !proTarriff) return <></>;

  return (
    <Card className={classes.table}>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TopTableCell> </TopTableCell>
              <TopTableCell style={{ color: 'white', fontWeight: 'bold' }} align='center'>
                {baseTarriff?.name || ''}
              </TopTableCell>
              <TopTableCell style={{ color: 'white', fontWeight: 'bold' }} align='center'>
                {proTarriff?.name || ''}
              </TopTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <b>{fm('PRICE.KM')}</b>
              </TableCell>
              <TableCell align='center' className={classes.freeCell}>
                <EditableCellTariff
                  tariff={baseTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='kilometerPrice'
                  fieldValue='kilometer_price'
                />
              </TableCell>
              <TableCell align='center' className={proClass}>
                <EditableCellTariff
                  tariff={proTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='kilometerPrice'
                  fieldValue='kilometer_price'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <b>{fm('MINIMUM.KM')}</b>
              </TableCell>
              <TableCell align='center' className={classes.freeCell}>
                <EditableCellTariff
                  tariff={baseTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='minimumKm'
                  fieldValue='minimum_km'
                />
              </TableCell>
              <TableCell align='center' className={proClass}>
                <EditableCellTariff
                  tariff={proTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='minimumKm'
                  fieldValue='minimum_km'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <b>{fm('PRICE.MINUTE.DAY')}</b>
              </TableCell>
              <TableCell align='center' className={classes.freeCell}>
                <EditableCellTariff
                  tariff={baseTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='daytimeMinutePrice'
                  fieldValue='daytime_minute_price'
                />
              </TableCell>
              <TableCell align='center' className={proClass}>
                <EditableCellTariff
                  tariff={proTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='daytimeMinutePrice'
                  fieldValue='daytime_minute_price'
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.titleCell}>
                <b>{fm('PRICE.MINUTE.NIGHT')}</b>
              </TableCell>
              <TableCell align='center' className={classes.freeCell}>
                <EditableCellTariff
                  tariff={baseTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='nighttimeMinutePrice'
                  fieldValue='nighttime_minute_price'
                />
              </TableCell>
              <TableCell align='center' className={proClass}>
                <EditableCellTariff
                  tariff={proTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='nighttimeMinutePrice'
                  fieldValue='nighttime_minute_price'
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className={classes.titleCell}>
                <b>{fm('MINIMUM.TIME')}</b>
              </TableCell>
              <TableCell align='center' className={classes.freeCell}>
                <EditableCellTariff
                  tariff={baseTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='minimumTime'
                  fieldValue='minimum_time'
                />
              </TableCell>
              <TableCell align='center' className={proClass}>
                <EditableCellTariff
                  tariff={proTarriff}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editable={editable}
                  field='minimumTime'
                  fieldValue='minimum_time'
                />
              </TableCell>
            </TableRow>

            {/* {canPay && (
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell className={proClass}>
                  <Button
                    onClick={() => {
                      if (!disablePay) {
                        setModal(true);
                      }
                    }}
                    className={classes.button}
                    variant='contained'
                    color={type === TariffsType.vendors ? 'secondary' : 'primary'}
                    disabled={disablePay}
                  >
                    Switch to Pro
                  </Button>
                </TableCell>
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default TariffTable;
