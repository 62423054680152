import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { DialogProps } from '@mui/material';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';

import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { getRole, getSubheaderId } from './utils';

import { actions as tariffActions, TariffEditFields } from '../../../store/ducks/tariff.duck';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { TariffChange, TariffTable } from './components';
import { PlanI } from './interfaces';
import { Modal } from '../../../components/other/Modals';
import { useDefineUserRole } from '../../../hooks';
import MyTariff from './components/UserCurrentTariff';

const getTariffFarePerioDays = (stop_datetime: Date, start_datetime: Date) => {
  return (
    dayjs(stop_datetime).diff(dayjs(start_datetime).format('YYYY-MM-DD'), 'day', false) + 1
  );
};

const useStyles = makeStyles((theme: any) => ({
  tariffInfo: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 20,
    marginBottom: 20,
    color: theme.palette.primary.main,
  },
}));

export enum TariffsType {
  vendors = 'vendors',
  buyers = 'buyers',
}
const TariffList: React.FC<TPropsFromRedux> = ({
  me,
  loadingMe,
  clearMe,
  fetchMe,

  tariffs,
  loading,
  success,
  fetch,

  edit,
  editSuccess,
  editError,
  clearEdit,

  clearPay,
  clearSubscription,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();
  const { type } = useParams();
  const navigate = useNavigate();
  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const intl = useIntl();

  const fetchTables = () => {
    fetch({ role: getRole(type as TariffsType) });
  };

  useEffect(() => {
    fetchTables();
  }, [fetch, type]);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
    };
  }, []);

  setLayoutSubheader({
    title: intl.formatMessage({
      id: isRoleAdmin ? getSubheaderId(type as TariffsType) : 'SUBMENU.MYTARIFF',
    }),
  });
  setLayoutFooter({ show: true });

  const submit = (id: number, field: TariffEditFields, value: string | number | null) => {
    edit({ id, data: { [field]: value } });
  };

  const editTariff = (
    id: number,
    forRole: string,
    name: string,
    field: TariffEditFields,
    value: string | number | null
  ) => {
    edit({ id, data: { forRole, name, [field]: value } });
  };

  // For edit actions
  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    clear: clearEdit,
    successMessage: 'Tariff changed',
    errorMessage: `Error: ${editError}`,
    afterSuccess: () => fetchTables(),
  });

  if (loading || loadingMe) return <Preloader />;

  let plans: PlanI[] = [];

  const closePayModal = () => {
    clearPay();
    clearSubscription();
    setIsModalOpen(false);
  };

  const afterSuccess = () => {
    closePayModal();
    navigate('/settings/profile');
  };

  return (
    <>
      {isRoleAdmin ? (
        <>
          {me?.current_user_tariff && (
            <div className={classes.tariffInfo}>
              <div>{`${intl.formatMessage({ id: 'TARIFF.CURRENT' })} ${
                me?.current_user_tariff.tariff.name
              }`}</div>
              {[4, 2].includes(me?.current_user_tariff.tariff.id) && (
                <>
                  <div>
                    {intl.formatMessage({ id: 'TARIFF.PERIOD' })}
                    {getTariffFarePerioDays(
                      me?.current_user_tariff.stop_datetime,
                      me?.current_user_tariff.start_datetime
                    )}{' '}
                    {intl.formatMessage({ id: 'TARIFF.DAYS' })}
                  </div>
                  <div>
                    {intl.formatMessage({ id: 'TARIFF.FARE_DATE' })}
                    {dayjs(me?.current_user_tariff.stop_datetime).format('YYYY-MM-DD')}
                  </div>
                </>
              )}
            </div>
          )}

          {tariffs && me && (
            <TariffTable
              type={type as TariffsType}
              editable={me?.is_admin || false}
              loading={loading}
              success={success}
              submit={submit}
              tariffs={tariffs}
              editTariff={editTariff}
              setModal={val => setIsModalOpen(val)}
              disablePay={
                me.current_user_tariff
                  ? [4, 2].includes(me?.current_user_tariff.tariff.id)
                  : false
              }
              canPay={false}
            />
          )}
        </>
      ) : (
        <MyTariff showBuyModal={() => {}} />
      )}

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={isModalOpen}
        onClose={() => {
          closePayModal();
        }}
        title=''
        loading={false}
        content={
          <TariffChange
            close={closePayModal}
            afterSuccess={() => afterSuccess()}
            plans={plans}
          />
        }
        actions={[
          {
            title: 'Cancel',
            onClick: () => {
              closePayModal();
            },
          },
        ]}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    tariffs: state.tariff.tariffs,
    loading: state.tariff.loading,
    success: state.tariff.success,

    editSuccess: state.tariff.editSuccess,
    editLoading: state.tariff.editLoading,
    editError: state.tariff.editError,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,

    clearPay: tariffActions.payInitClear,
    clearSubscription: tariffActions.paySubscriptionClear,
    fetch: tariffActions.fetchRequest,
    edit: tariffActions.editRequest,
    clearEdit: tariffActions.clearEdit,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TariffList);
