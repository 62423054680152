import { createTheme } from '@mui/material/styles';
import React from 'react';
import { ThemeProvider as MuiThemeProvider, darken } from '@mui/material';

const theme = createTheme(
  /**
   * @see https://material-ui.com/customization/themes/#theme-configuration-variables
   */
  {
    // direction: "rtl",
    typography: {
      fontFamily: ['Montserrat'].join(','),
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 600, // Small screen / phone
        md: 920, // Medium screen / tablet
        lg: 1200, // Large screen / desktop
        xl: 1500,
      },
    },

    palette: {
      contrastThreshold: 3,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#FF8B00',
        contrastText: '#000000',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: will be calculated from palette.primary.main,
        main: '#b8bfbe',
        // dark: will be calculated from palette.primary.main,
        contrastText: '#000000',
      },
      error: {
        // light: will be calculated from palette.primary.main,
        main: '#b12a27',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      success: {
        // light: will be calculated from palette.primary.main,
        main: '#b8bfbe',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },

    /**
     * @see https://material-ui.com/customization/globals/#default-props
     */

    components: {
      MuiButton: {
          styleOverrides: {
              root: {
                  border:'none',
                  borderRadius: 10,
                  padding:'9px 26px',
                  fontSize: 15,
                  fontWeight: 'bold',
                  // textTransform:'inherit',
                  '&:hover': {
                      border:'none',
                  },
              },
              outlined: {
                  backgroundColor: '#c0ccc1',
                  '&:hover': {
                      backgroundColor: '#b0c0b3',
                  },
              },
              containedPrimary: {
                background: '#ffce00',
                  '&:hover': {
                    backgroundColor: darken('#ffce00', 0.1),
                  },
              },
          },
      },
    },
  },
);

export default function ThemeProviderMui(props: any) {
  const { children } = props;

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
