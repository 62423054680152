import { makeStyles } from '@mui/styles';

export const useStylesMyTariff = makeStyles(() => ({
  container: {
    display: 'flex',
    margin: '15px 75px',
    backgroundColor: '#FFFFFFFF',
    padding: '25px 0px',
    borderRadius: 4,
    flexDirection: 'column',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:800px)']: {
      margin: 0,
    },
  },
  header: {
    display: 'flex',
    marginBottom: 25,
    marginLeft: 29,
    marginRight: 29,
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#181C32FF',
    margin: 0,
    padding: 0,
  },
  content: {
    display: 'flex',
    padding: '38px 29px',
    borderTop: '1px solid  #F2F2EBFF',
    borderBottom: '1px solid  #F2F2EBFF',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  tariffInfo: {
    display: 'flex',
    padding: '25px 20px',
    marginRight: 20,
    border: 'dashed 1px #e4e6ef',
    borderRadius: 4,
    flexDirection: 'row',
    height: 79,
    marginBottom: 20,
    width: 275,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:800px)']: {
      marginRight: 0,
    },
  },
  tariffName: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#181C32FF',
    margin: 0,
    padding: 0,
    marginLeft: 20,
  },
  tariffsContainer: {
    display: 'flex',
    borderRadius: 4,
    backgroundColor: '#F2F2EBFF',
    padding: 35,
    paddingLeft: 31,
    flexDirection: 'column',
    width: 570,
  },
  tariffsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#181C32FF',
    margin: 0,
    padding: 0,
    marginBottom: 5,
  },
  tariffSubTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#A1A5B7FF',
    margin: 0,
    padding: 0,
    marginBottom: 23,
  },
  tariffOption: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5E6278FF',
    margin: 0,
    padding: 0,
    marginBottom: 27,
    marginRight: 31,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconChecked: {
    height: 21,
    width: 21,
    marginBottom: 27,
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  btnFooterWrapper: {
    display: 'flex',
    width: 865,
    justifyContent: 'flex-end',
    marginTop: 25,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1139px)']: {
      justifyContent: 'center',
    },
  },
  btnFooter: {
    display: 'flex',
    width: 205,
    backgroundColor: '#369FF7FF',
    height: 42,
    borderRadius: 4,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: 0.9,
    },
  },
  btnTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#FFFFFF',
    margin: 0,
    padding: 0,
  },
}));

export const useStylesModalTariff = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  closeWrapper: {
    display: 'flex',
    width: '100%',
    padding: '34px 43px',
    paddingBottom: 12,
    justifyContent: 'flex-end',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 21,
    fontWeight: 'bold',
    color: '#181C32FF',
    margin: 0,
    padding: 0,
    marginBottom: 13,
    textAlign: 'center',
  },
  headerSubTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#A1A5B7FF',
    margin: 0,
    padding: 0,
    marginBottom: 29,
    textAlign: 'center',
  },
  tariffBox: {
    display: 'flex',
    height: 99,
    width: 495,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'dashed 1px #e4e6ef',
    marginBottom: 20,
    marginRight: 20,
    marginLeft: 20,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 4,
    cursor: 'pointer',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:900px)']: {
      width: '100%',
      marginRight: 0,
      marginLeft: 0,
    },
  },
  tariffTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#181C32FF',
    margin: 0,
    padding: 0,
    marginBottom: 7,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:600px)']: {
      fontSize: 15,
      marginRight: 10,
    },
  },
  wrapperTariffs: {
    display: 'flex',
    flexDirection: 'column',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:600px)']: {
      width: 495,
    },
  },
  tariffSubTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#A1A5B7FF',
    margin: 0,
    padding: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:600px)']: {
      fontSize: 12,
    },
  },
  tariffPriceWrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  tariffPrice: {
    fontSize: 40,
    fontWeight: 600,
    color: '#181C32FF',
    margin: 0,
    padding: 0,
    marginRight: 5,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:600px)']: {
      fontSize: 20,
    },
  },
  tariffPriceRub: {
    fontSize: 14,
    fontWeight: 600,
    color: '#181C32FF',
    margin: 0,
    padding: 0,
  },
  tariffOption: {
    fontSize: 14,
    fontWeight: 500,
    color: '#5E6278FF',
    margin: 0,
    padding: 0,
    marginBottom: 27,
    marginRight: 31,
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconChecked: {
    height: 21,
    width: 21,
    marginBottom: 27,
  },
  tariffsContainer: {
    display: 'flex',
    borderRadius: 4,
    backgroundColor: '#F2F2EBFF',
    padding: 35,
    paddingLeft: 31,
    flexDirection: 'column',
    width: 570,
    paddingBottom: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:900px)']: {
      width: '100%',
      margin: '0 10px',
    },
  },
  tariffsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#181C32FF',
    margin: 0,
    padding: 0,
    marginBottom: 5,
  },
  tariffSubTitle1: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#A1A5B7FF',
    margin: 0,
    padding: 0,
    marginBottom: 23,
  },
  btnFooter: {
    display: 'flex',
    width: 205,
    backgroundColor: '#369FF7FF',
    height: 42,
    borderRadius: 4,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    '&:hover': {
      opacity: 0.9,
    },
  },
  btnTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: '#FFFFFF',
    margin: 0,
    padding: 0,
  },
  btnFooterCancel: {
    display: 'flex',
    width: 105,
    backgroundColor: '#F2F2EBFF',
    height: 42,
    borderRadius: 4,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    marginTop: 20,
    '&:hover': {
      opacity: 0.9,
    },
  },
  btnTitleCancel: {
    fontSize: 14,
    fontWeight: 500,
    color: '#84889EFF',
    margin: 0,
    padding: 0,
  },
  footerBtns: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  bullet: {
    height: 23,
    width: 23,
    marginRight: 20,
  },
  wrapperTariffBlocks: {
    display: 'flex',
    flexDirection: 'column',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:900px)']: {
      width: '100%',
      margin: '0 10px',
    },
  },
}));
