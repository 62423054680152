import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';

// import enMessages from './messages/en.json';
import ruMessages from './messages/ru.json';
// import czMessages from './messages/cz.json';

const allMessages: { [key: string]: any } = {
  // en: enMessages,
  ru: ruMessages,
  // cz: czMessages,
};

export default function I18nProvider({ children }: { children: React.ReactNode }) {
  // const locale = useSelector(({ i18n }: any) => i18n.lang);
  const messages = allMessages['ru'];

  return (
    <IntlProvider locale='ru' messages={messages}>
      {children}
    </IntlProvider>
  );
}
