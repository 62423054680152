import React from 'react';
import EditableCell from '../TariffTable/components/EditableCell';
import { ITariff } from '../../../../../interfaces/tariff';
import { TariffEditFields } from '../../../../../store/ducks/tariff.duck';

const EditableCellTariff: React.FC<{
  tariff: ITariff;
  editable: boolean;
  loading: boolean;
  success: boolean;
  fieldValue: keyof ITariff;
  field: TariffEditFields;
  submit: (id: number, field: TariffEditFields, value: string | number | null) => void;
}> = ({ tariff, editable, loading, success, field, fieldValue, submit }) => {
  return (
    <EditableCell
      cell={{
        value: tariff[fieldValue] || 0,
        field,
        text: tariff[fieldValue] || 0,
      }}
      loading={loading}
      success={success}
      submit={newValue => submit(tariff.id, field, newValue || 0)}
      editable={editable}
    />
  );
};

export default EditableCellTariff;
