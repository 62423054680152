import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Tooltip,
  Avatar,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useIntl } from 'react-intl';
import { SHeadTableCell } from '../../../../components/styledComponents/Tables';
import homeStyles from '../../../../constants/homeStyles';
import { ButtonWithLoader } from '../../../../components/ui/Buttons';
import { ButtonTheme } from '../../../../components/ui/Buttons/ButtonWithLoader';
import { getFio } from '../../users/users/utils';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IUser, UserRoles } from '../../../../interfaces/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      marginRight: theme.spacing(3),
    },

    card: {
      // marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: 20,
      marginRight: 20,
      minWidth: 650,
    },
    cardTitle: {
      marginLeft: theme.spacing(4),
      fontSize: 17,
      fontWeight: 700,
      color: '#000',
    },

    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    img: {
      maxHeight: 55,
      cursor: 'pointer',
    },
    emptyCompany: {
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    green: {
      color: '#fff',
      backgroundColor: '#441861',
    },
    head: {
      fontWeight: 'bold',
      fontSize: 14,
      paddingBottom: 17,
      paddingTop: 17,
    },
  })
);
interface ISortableCategoryItem {
  me: IUser;
  users: IUser[];
  type: string;
}

const TableNewVendors: React.FC<ISortableCategoryItem> = ({ users, me, type }) => {
  const intl = useIntl();
  const fm = useFormatMessage();
  const navigate = useNavigate();
  const homeClasses = homeStyles();
  const classes = useStyles();
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isBuyer = useDefineUserRole(me, ['ROLE_BUYER', 'ROLE_BUYER_STAFF']);

  return (
    <>
      <Card className={classes.card}>
        <text className={classes.cardTitle}>
          {' '}
          {intl.formatMessage({ id: 'SUBMENU.NEW.VENDORS' })}
        </text>
        <CardContent className={homeClasses.classes.tableContainer}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <SHeadTableCell className={classes.head}>{fm('USER.TABLE.ID')}</SHeadTableCell>
                {/* <SHeadTableCell style={{ textAlign: 'center' }}> */}
                {/*   {intl.formatMessage({ id: 'SETTING.PROFILE.PHOTO' })} */}
                {/* </SHeadTableCell> */}
                <SHeadTableCell className={classes.head}>
                  {fm('USER.TABLE.LOGIN')}
                </SHeadTableCell>
                <SHeadTableCell className={classes.head}>
                  {fm('USER.TABLE.EMAIL')}
                </SHeadTableCell>
                {/* <SHeadTableCell>{fm('USER.TABLE.ROLE')}</SHeadTableCell> */}
                <SHeadTableCell className={classes.head}>
                  {fm('USER.TABLE.FIO')}
                </SHeadTableCell>
                {/* <SHeadTableCell>Статус</SHeadTableCell> */}
                {!me?.roles.includes(UserRoles.ROLE_MANAGER) && !isBuyer && (
                  <SHeadTableCell className={classes.head}>
                    {fm('USER.TABLE.COMPANY')}
                  </SHeadTableCell>
                )}
                {/* {isBuyer && (
                          <>
                            <SHeadTableCell align='center'>
                              {fm('USER.TABLE.TARIFF')}
                            </SHeadTableCell>
                            <SHeadTableCell align='center'>
                              {fm('USER.TABLE.STOPDATETARIFF')}
                            </SHeadTableCell>
                          </>
                        )} */}

                <SHeadTableCell align='center' className={classes.head}>
                  {fm('USER.TABLE.ACTION')}
                </SHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((item, index) =>
                index < 3 ? (
                  <TableRow key={item.id}>
                    <TableCell component='th' scope='row'>
                      {item.id || '-'}
                    </TableCell>
                    {/* <TableCell> */}
                    {/*   {item.avatar ? ( */}
                    {/*     <Avatar */}
                    {/*       style={{ margin: '0 auto' }} */}
                    {/*       src={`${API_DOMAIN}/${ */}
                    {/*         typeof item.avatar === 'string' */}
                    {/*           ? item.avatar */}
                    {/*           : item.avatar.small */}
                    {/*       }`} */}
                    {/*       className={classes.green} */}
                    {/*     /> */}
                    {/*   ) : ( */}
                    {/*     <div className={classes.companyNameAvatarWrapper}> */}
                    {/*       <span */}
                    {/*         className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success' */}
                    {/*         style={{ */}
                    {/*           width: 40, */}
                    {/*           height: 40, */}
                    {/*           borderRadius: 20, */}
                    {/*           backgroundColor: '#566DC6FF', */}
                    {/*         }} */}
                    {/*       > */}
                    {/*         <b> */}
                    {/*           {(item.first_name */}
                    {/*             ? item.first_name.trim()[0] */}
                    {/*             : '' */}
                    {/*           ).toUpperCase()} */}
                    {/*         </b> */}
                    {/*       </span> */}
                    {/*     </div> */}
                    {/*   )} */}
                    {/* </TableCell> */}
                    <TableCell>{item.login || '-'}</TableCell>
                    <TableCell>{item.email || '-'}</TableCell>
                    {/* <TableCell>{getRoleName(item.roles || [], intl)}</TableCell> */}
                    <TableCell>{item.fio || '-'}</TableCell>
                    {/* <TableCell>
                            <StatusIndicator isActive={item.active} />
                          </TableCell> */}
                    {!me?.roles.includes(UserRoles.ROLE_MANAGER) && !isBuyer && (
                      <TableCell>
                        {item.company ? (
                          <Tooltip title={item.company?.name} aria-label={item.company?.name}>
                            <Avatar className={classes.green} alt={item.company?.name}>
                              {item.company?.name.slice(0, 1)}
                            </Avatar>
                          </Tooltip>
                        ) : (
                          <div className={classes.emptyCompany}>-</div>
                        )}
                      </TableCell>
                    )}
                    {/* {isBuyer && (
                            <>
                              {!item.current_user_tariff ? (
                                <>
                                  <TableCell align='center' />
                                  <TableCell align='center' />
                                </>
                              ) : (
                                <>
                                  <TableCell align='center'>
                                    <p
                                      className={classes.tariffText}
                                      onClick={() => setEditUserTariff(item)}
                                    >
                                      {item.current_user_tariff.tariff.name === 'Премиум'
                                        ? 'Профессиональный'
                                        : 'Базовый'}
                                    </p>
                                  </TableCell>
                                  <TableCell align='center'>
                                    {item.current_user_tariff.tariff.name === 'Премиум'
                                      ? getDateStop(item.current_user_tariff)
                                      : ''}
                                  </TableCell>
                                </>
                              )}
                            </>
                          )} */}
                    <TableCell align='right'>
                      <div className={classes.actions}>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'PRODUCTS.TABLE.TOOLTIP.EDIT',
                          })}
                        >
                          <IconButton
                            color='primary'
                            aria-label='previous'
                            onClick={() => {
                              // clearDelUser();
                              navigate(`/users/edit/${type}/${item.id}`);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        {me.is_admin ||
                          (isManager && (
                            <Tooltip
                              title={intl.formatMessage({
                                id: 'PRODUCTS.TABLE.TOOLTIP.DELETE',
                              })}
                            >
                              <IconButton
                                aria-label='previous'
                                onClick={() => {
                                  // setUserId(item.id);
                                  // setOpen(true);
                                }}
                              >
                                <DeleteIcon color='error' />
                              </IconButton>
                            </Tooltip>
                          ))}
                        {/* {isAdmin && ( */}
                        {/*   <IconButton */}
                        {/*     onClick={() => { */}
                        {/*       // newChat(item.company?.id, item.id) */}
                        {/*       history.push({ */}
                        {/*         pathname: `/chats`, */}
                        {/*         state: { */}
                        {/*           createCompanyChat: item?.company, */}
                        {/*           createUserChat: item, */}
                        {/*         }, */}
                        {/*       }); */}
                        {/*     }} */}
                        {/*     color='primary' */}
                        {/*     aria-label='previous' */}
                        {/*   > */}
                        {/*     <MessageIcon /> */}
                        {/*   </IconButton> */}
                        {/* )} */}
                      </div>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
            {/* <TableFooter> */}
            {/*    <TableRow> */}
            {/*        <TablePaginator */}
            {/*            page={page} */}
            {/*            realPerPage={users.length} */}
            {/*            perPage={perPage} */}
            {/*            total={total} */}
            {/*            fetchRows={(data: any) => fetch({ ...data, role, filter })} */}
            {/*            label={fm('USER.TABLE.PER_PAGE')} */}
            {/*        />  */}
            {/*    </TableRow> */}
            {/* </TableFooter> */}
          </Table>
        </CardContent>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonWithLoader
            style={{ marginBottom: 25, marginRight: 10 }}
            theme={ButtonTheme.PRIMARY}
            onPress={() => navigate('/users/vendors')}
          >
            {intl.formatMessage({ id: 'CATEGORIES.BUTTON.VIEW.ALL' })}
          </ButtonWithLoader>
        </div>
      </Card>
    </>
  );
};

export default TableNewVendors;
