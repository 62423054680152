import React, { useEffect, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from '@mui/material';
import { useFormatMessage } from '../../../hooks';

interface IProps {
  src: string;
  onSubmit: (completedCrop: any) => void;
  aspect?: number;
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: mediaWidth - 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

const generateBlob = async (cropProp: any, image: any, setBlob: any) => {
  setBlob(null);
  const blob = await new Promise(resolve => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(cropProp.width * scaleX);
    canvas.height = Math.ceil(cropProp.height * scaleY);
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    // Clear the canvas and add a transparent border
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const newImage = new Image();
    newImage.crossOrigin = 'anonymous';
    newImage.onload = () => {
      // Draw the cropped image onto the canvas
      ctx.drawImage(
        newImage,
        cropProp.x * scaleX,
        cropProp.y * scaleY,
        cropProp.width * scaleX,
        cropProp.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      canvas.toBlob(
        blob => {
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    };
    newImage.src = image.src;
  });
  setBlob(blob);
};

export const CroppedImage: React.FC<IProps> = ({ src, onSubmit, aspect = 16 / 9 }) => {
  const [img, setImg] = useState<any>(null);
  const [crop, setCrop] = useState<any>(null);
  const [blob, setBlob] = useState<string | null>(null);

  const fm = useFormatMessage();

  useEffect(() => {
    if (img) {
      const { width, height } = img;
      const initialCrop = centerAspectCrop(width, height, aspect);
      setCrop(initialCrop);
      generateBlob(initialCrop, img, setBlob);
    }
  }, [img, src]);

  const handleSubmit = () => {
    if (img && crop) {
      generateBlob(crop, img, setBlob);
      onSubmit(blob);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 300 }}>
      <ReactCrop
        crop={crop}
        aspect={aspect}
        onChange={(c: any) => setCrop(c)}
        onComplete={(c: any) => {
          if (blob) window.URL.revokeObjectURL(blob);
          generateBlob(c, img, setBlob);
        }}
      >
        <img src={src} onLoad={e => setImg(e.currentTarget)} alt='source' />
      </ReactCrop>

      <Button
        onClick={handleSubmit}
        variant='contained'
        color='primary'
        disabled={!blob}
        style={{ marginTop: 15, color: 'white' }}
      >
        {fm('COMMON.BUTTON.APPLY')}
      </Button>
    </div>
  );
};
