import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import {
  Button,
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Avatar,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useIntl } from 'react-intl';
import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import { Modal } from '../../../components/other/Modals';
import TablePaginator from '../../../components/tableComponents/TablePaginator/TablePaginator';
import CompanyBind from './CompanyBind';
import { actions as tariffActions } from '../../../store/ducks/tariff.duck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { IAppState } from '../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import homeStyles from '../../../constants/homeStyles';
import { UserListType } from '../users/constants';
import { UserRoles } from '../../../interfaces/user';
import { ICompany } from '../../../interfaces/companies';
import { getDateStop } from '../tariffs/utils/getStopDate';
import TariffModal from '../tariffs/components/UserCurrentTariff/TariffsModal';
import { API_DOMAIN } from '../../../constants';
import { toAbsoluteUrl } from '../../../../_base';
import { useStylesCompanies } from './hooks/useStyles';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';

const СompaniesPage: React.FC<TPropsFromRedux> = ({
  page,
  perPage,
  total,
  companies,
  fetch,
  loading,
  delCompanySuccess,
  clearDelCompany,
  delCompany,
  delCompanyLoading,
  delCompanyError,
  clearCompanies,
  me,
  fetchMe,
  loadingMe,
  clearMe,
  fetchTariffs,
  loadingTariff,
  tariffs,
}) => {
  const classes1 = useStylesCompanies();
  const { classes } = classes1;
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const homeClasses = homeStyles();
  const [open, setOpen] = useState(false);
  const [companyId, setCompanyId] = useState<number>();
  const [headerName, setHeaderName] = useState('');
  const intl = useIntl();
  const [editCompanyTariff, setEditCompanyTariff] = useState<ICompany | null>(null);
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  useCrudSnackbar({
    success: delCompanySuccess,
    error: delCompanyError,
    clear: clearDelCompany,
    successMessage: intl.formatMessage({ id: 'COMPANY.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delCompanyError}`,
    afterSuccessOrError: () => {
      clearDelCompany();
      setOpen(false);
    },
    afterSuccess: () => fetch({ page, perPage }),
  });

  const successEditCompanyTariff = useCallback(() => {
    setEditCompanyTariff(null);
    fetch({ page, perPage });
  }, [page, perPage]);

  useEffect(() => {
    fetchMe();
    return () => {
      clearMe();
      clearCompanies();
    };
  }, []);

  useEffect(() => {
    if (me) {
      fetchTariffs({ role: UserRoles.ROLE_VENDOR });
    }
    if (!me?.company) {
      if (me?.is_admin || isManager) {
        setHeaderName(intl.formatMessage({ id: 'SUBMENU.COMPANIES' }));
        fetch({ page, perPage });
      } else {
        setHeaderName(intl.formatMessage({ id: 'SUBMENU.COMPANY_SETTINGS' }));
      }
    }
  }, [me]);

  useEffect(() => {
    if (loadingMe || !me?.company) return;
    navigate(`/companies/edit/${me?.company?.id.toString()}`);
  }, [me, loadingMe]);

  setLayoutSubheader({
    title: headerName,
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  if (!me || loadingMe || !companies || loading || loadingTariff) return <Preloader />;

  if (me && !isManager && !me.is_admin && !me.company) {
    return <CompanyBind />;
  }

  return (
    <>
      <Row>
        <Col>
          <Button
            color='primary'
            variant='contained'
            onClick={() => navigate('/companies/new')}
            className={classes.addBtn}
          >
            {intl.formatMessage({ id: 'SUBMENU.COMPANIES.ADD' })}
          </Button>
          {!companies.length ? (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          ) : (
            <>
              <Card className={classes.card}>
                <CardContent className={homeClasses.classes.tableContainer}>
                  <Table aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'COMPANY.TABLE.ID' })}
                        </SHeadTableCell>
                        <SHeadTableCell style={{ textAlign: 'center' }}>
                          {intl.formatMessage({ id: 'COMPANY.TAB.LOGO' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'COMPANY.TABLE.NAME' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'COMPANY.TABLE.LEGAL_PERSON' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'COMPANY.TABLE.INN' })}
                        </SHeadTableCell>
                        <SHeadTableCell>
                          {intl.formatMessage({ id: 'COMPANY.TABLE.MANAGERS' })}
                        </SHeadTableCell>
                        {me.is_admin && (
                          <>
                            <SHeadTableCell align='center'>
                              {intl.formatMessage({ id: 'USER.TABLE.TARIFF' })}
                            </SHeadTableCell>
                            <SHeadTableCell align='center'>
                              {intl.formatMessage({ id: 'USER.TABLE.STOPDATETARIFF' })}
                            </SHeadTableCell>
                          </>
                        )}
                        <SHeadTableCell align='right'>
                          {intl.formatMessage({ id: 'COMPANY.TABLE.ACTIONS' })}
                        </SHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companies.map(item => (
                        <TableRow key={item.id}>
                          <TableCell component='th' scope='row'>
                            {item.id}
                          </TableCell>

                          <TableCell>
                            {item.logo || !item.brand ? (
                              <Avatar
                                style={{ margin: '0 auto' }}
                                src={
                                  item.logo
                                    ? `${API_DOMAIN}/${item.logo.small}`
                                    : toAbsoluteUrl('/images/placeholder.png')
                                }
                                className={classes.green}
                              />
                            ) : (
                              <div className={classes.companyNameAvatarWrapper}>
                                <span
                                  className='kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success'
                                  style={{
                                    width: 40,
                                    height: 40,
                                    borderRadius: 20,
                                    backgroundColor: '#566DC6FF',
                                  }}
                                >
                                  <b>
                                    {(item.brand ? item.brand.trim()[0] : '').toUpperCase()}
                                  </b>
                                </span>
                              </div>
                            )}
                          </TableCell>

                          <TableCell>
                            {/* <Link to={`/viewcompany/${item?.id}`}>{item.brand}</Link> */}
                            <Link to={`/products/list/U${item.id}/${item.id}`}>
                              {item.brand}
                            </Link>
                          </TableCell>

                          <TableCell>{item.name}</TableCell>

                          <TableCell>{item.inn}</TableCell>

                          <TableCell>
                            {item.managers &&
                              item.managers.length > 0 &&
                              [...item.managers.slice(0, 3)].map(manager => (
                                <span key={manager.id} style={{ display: 'block' }}>
                                  <Link
                                    to={`/users/edit/${UserListType.MANAGER}/${manager.id}`}
                                  >
                                    {manager.first_name ||
                                    manager.last_name ||
                                    manager.middle_name
                                      ? `${manager.last_name || ''} ${
                                          manager.first_name || ''
                                        } ${manager.middle_name || ''}`
                                      : manager.email}
                                  </Link>
                                </span>
                              ))}
                          </TableCell>

                          {me.is_admin && (
                            <>
                              {!item.current_tariff ? (
                                <>
                                  <TableCell align='center' />
                                  <TableCell align='center' />
                                </>
                              ) : (
                                <>
                                  <TableCell align='center'>
                                    <p
                                      className={classes.tariffText}
                                      onClick={() => setEditCompanyTariff(item)}
                                    >
                                      {item.current_tariff.tariff.name === 'Премиум'
                                        ? fm('TARIFF.PRIME')
                                        : fm('TARIFF.BASE')}
                                    </p>
                                  </TableCell>
                                  <TableCell align='center'>
                                    {item.current_tariff.tariff.name === 'Премиум'
                                      ? getDateStop(item.current_tariff)
                                      : ''}
                                  </TableCell>
                                </>
                              )}
                            </>
                          )}

                          <TableCell align='right'>
                            <div className={classes.actions}>
                              <IconButton
                                color='primary'
                                aria-label='previous'
                                onClick={() => navigate(`/companies/edit/${item.id}`)}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                color='primary'
                                aria-label='previous'
                                onClick={() =>
                                  navigate(`/products/create/company/${item.id}/${item.name}`)
                                }
                              >
                                <AddIcon />
                              </IconButton>
                              <IconButton
                                color='primary'
                                aria-label='previous'
                                onClick={() => {
                                  navigate(`/products/list/U${item.id}/${item.id}`);
                                }}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  enableBackground='new 0 0 24 24'
                                  height='24px'
                                  viewBox='0 0 24 24'
                                  width='24px'
                                  fill='#ffce00'
                                >
                                  <rect fill='none' height='24' width='24' />
                                  <path d='M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3L19,3z' />
                                  <path d='M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z' />
                                </svg>
                              </IconButton>
                              {me.is_admin && (
                                <IconButton
                                  color='secondary'
                                  aria-label='previous'
                                  onClick={() => {
                                    setCompanyId(item.id);
                                    setOpen(true);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePaginator
                          page={page}
                          realPerPage={companies.length}
                          perPage={perPage}
                          total={total}
                          fetchRows={fetch}
                          label={intl.formatMessage({ id: 'COMPANY.TABLE.PERPAGE' })}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </Card>
            </>
          )}
        </Col>
      </Row>
      <TariffModal
        open={Boolean(editCompanyTariff)}
        handleClose={() => setEditCompanyTariff(null)}
        company={editCompanyTariff}
        successFunc={successEditCompanyTariff}
        tariffs={tariffs}
      />
      <Modal
        open={open}
        onClose={delCompanyLoading ? () => {} : () => setOpen(false)}
        title={intl.formatMessage({ id: 'COMPANY.DELETE.TITLE' })}
        loading={delCompanyLoading}
        content={
          delCompanyLoading ? undefined : intl.formatMessage({ id: 'COMPANY.DELETE.TEXT' })
        }
        actions={
          delCompanyLoading
            ? undefined
            : [
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }),
                  onClick: () => setOpen(false),
                },
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' }),
                  onClick: () => companyId && delCompany(companyId),
                },
              ]
        }
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.companies.page,
    perPage: state.companies.per_page,
    total: state.companies.total,
    companies: state.companies.companies,
    loading: state.companies.loading,
    delCompanySuccess: state.companies.delCompanySuccess,
    delCompanyLoading: state.companies.delCompanyLoading,
    delCompanyError: state.companies.delCompanyError,
    loadingTariff: state.tariff.loading,
    tariffs: state.tariff.tariffs,

    me: state.profile.me,
    loadingMe: state.profile.loading,
    successMe: state.profile.success,
  }),
  {
    fetch: companiesActions.fetchRequest,
    clearDelCompany: companiesActions.clearDelCompany,
    delCompany: companiesActions.delCompanyRequest,
    clearCompanies: companiesActions.clearCompanies,

    fetchMe: profileActions.fetchRequest,
    clearMe: profileActions.clearMe,
    fetchTariffs: tariffActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(СompaniesPage);
