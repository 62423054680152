import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { makeStyles } from '@mui/styles';
import { InputLabel, TextField } from '@mui/material';
import { ITypeParameter } from '../../../../interfaces/productType';
import NumberFormatCustom from '../../../../components/NumberFormatCustom';
import { useFormatMessage } from '../../../../hooks';

interface IProps {
  productTypeFilter: Partial<ITypeParameter>;
  handleChange: any;
  deleteFilteData?: (key: number, deleteSearchParam?: boolean) => void;
  defaultFilter?: any;
}

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: 16,
  },
  filterWrap: {
    display: 'flex',
  },
  input: {
    height: 38,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#f2f2eb',
    padding: '0 8px',
    margin: '0 8px',
    borderRadius: 4,
  },
  label: {
    marginBottom: 7,
    fontSize: 14,
    fontWeight: 600,
    color: '#181c32',
    marginLeft: 8,
  },
}));

const SelectFilterRange: React.FC<IProps> = ({
  productTypeFilter,
  handleChange,
  deleteFilteData,
  defaultFilter,
}) => {
  const classes = useStyles();
  const [rangeFrom, setRangeFrom] = useState<string | number>('');
  const [rangeTo, setRangeTo] = useState<string | number>('');
  const [rangeData, setRangeData] = useState<(string | number)[]>(['', '']);
  const fm = useFormatMessage();

  useEffect(() => {
    if (defaultFilter) {
      !!+defaultFilter?.values[0] &&
        rangeFrom?.toString() !== defaultFilter?.values[0]?.toString() &&
        setRangeFrom(defaultFilter.values[0]);
      !!+defaultFilter?.values[1] &&
        rangeTo?.toString() !== defaultFilter?.values[1]?.toString() &&
        setRangeTo(defaultFilter.values[1]);
    }
  }, [defaultFilter]);

  const setDataFrom = useCallback(
    debounce((value: string) => {
      setRangeData(prev => {
        if (value === '' && rangeTo) {
          return ['0', rangeTo];
        }
        if (!prev[1] && value !== '') {
          return [value.replace(/-/gi, '')];
        }
        if (prev[0].toString() !== value.toString()) {
          return [value.replace(/-/gi, ''), prev[1]];
        }
        return prev;
      });
    }, 500),
    [rangeTo]
  );

  const setDataTo = useCallback(
    debounce((value: string) => {
      setRangeData(prev => {
        if (value === '' && prev[0]) {
          return [prev[0].toString()];
        }
        if (prev[1]?.toString() !== value.toString()) {
          return [prev[0], value.replace(/-/gi, '')];
        }
        return prev;
      });
    }, 500),
    [rangeFrom]
  );

  const handlerFrom = useCallback((value: string) => {
    setRangeFrom(value.replace(/-/gi, ''));
    setDataFrom(value.replace(/-/gi, ''));
  }, []);

  const handlerTo = useCallback((value: string) => {
    setRangeTo(value.replace(/-/gi, ''));
    setDataTo(value.replace(/-/gi, ''));
  }, []);

  useEffect(() => {
    if (!!rangeData[0] || !!rangeData[1]) {
      handleChange(Number(productTypeFilter.id), rangeData);
    } else {
      deleteFilteData && productTypeFilter.id && deleteFilteData(productTypeFilter.id);
    }
  }, [rangeData]);

  return (
    <>
      <div className={classes.container}>
        <InputLabel className={classes.label} shrink>
          {productTypeFilter.name}
        </InputLabel>
        <div className={classes.filterWrap}>
          <TextField
            placeholder={fm('PRODUCT.FILTER.FROM')}
            value={rangeFrom}
            onChange={event => {
              handlerFrom(event.target.value);
            }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputMode: 'numeric',
              className: classes.input,
            }}
            variant='outlined'
          />
          <TextField
            placeholder={fm('PRODUCT.FILTER.TO')}
            value={rangeTo}
            onChange={event => {
              handlerTo(event?.target?.value.replace(/-/gi, ''));
            }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputMode: 'numeric',
              className: classes.input,
            }}
            variant='outlined'
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(SelectFilterRange);
