import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { getResponseMessage } from '../../../../../utils/utils';
import { useFormatMessage } from '../../../../../hooks';
import { editCompany } from '../../../../../crud/companies.crud';
import { ICompanyEditProps } from '../../../../../interfaces/companies';

export const useEditCompany = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loadingCompany, setLoading] = useState(false);
  const [successCompany, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchCompany = useCallback(async (id: number, data: ICompanyEditProps) => {
    setLoading(true);
    editCompany(id, data)
      .then(() => {
        setSuccess(true);
        // enqueueSnackbar(fm('COMPANY.EDIT.SUCCESS'), { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 200);
      });
  }, []);

  return { fetchCompany, successCompany, loadingCompany };
};
