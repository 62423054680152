import { makeStyles } from '@mui/styles';

export const useStylesEditPage = makeStyles((theme: any) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
  btnDel: {
    marginLeft: theme.spacing(2),
  },
  delButton: {
    marginLeft: theme.spacing(2),
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  companyBox: {},
  companyTitle: {},
  companyField: {
    display: 'flex',
    alignItems: 'center',
  },
  allCompaniesBtn: {
    marginTop: 8,
    marginLeft: 10,
    height: 52,
  },
  dates: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  containerName: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldName: {
    width: '50%',
  },
}));
