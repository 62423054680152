import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Divider,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import enLocale from 'date-fns/locale/en-US';
import ruLocale from 'date-fns/locale/ru';
import csLocale from 'date-fns/locale/cs';
// @ts-ignore
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import { actions as cartActions } from '../../../store/ducks/cart.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { CartItems } from './components';
import { IAppState } from '../../../store/rootDuck';
import { Modal } from '../../../components/other/Modals';
import AutocompleteLocations from '../../../components/AutocompleteLocations';
import { actions as locationActions } from '../../../store/ducks/yaLocations.duck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import ButtonWithLoader from '../../../components/ui/Buttons/ButtonWithLoader';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { IStore } from '../../../interfaces/store';
import { thousands } from '../../../utils/utils';
import ImageDialog from '../../../components/ui/ImageDialog/imageDialog';
import { ILocation } from '../../../interfaces/locations';
import { ICart, IGuestCart } from './interfaces';
import { IUser } from '../../../interfaces/user';
import { AuthDialog } from '../../auth/AuthDialog';
import { useFormatMessage } from '../../../hooks';
// import { PHONE_MASK } from '../../../constants';
import Bonuses from '../settings/components/Bonuses';
import { useOpenPayWidget } from '../../../hooks/useOpenPayWidget';
import AlertDialog from '../../../components/ui/Dialog/AlertDialog';
import { IMaskPhoneInput } from '../../auth/components/CheckInSystem';
import { useAxiosGetCallback } from '../../../hooks/useAxiosGet';
import { useHandleCountrySelect } from '../../auth/hooks/useHandleCountrySelect';
// import SelectField from '../../../components/ui/SelectField';
import ItemCart from './components/ItemCart';
import CountrySelect from '../../../components/ui/CountrySelect';

const monthValues = {
  narrow: ['Я', 'Ф', 'М', 'А', 'М', 'И', 'И', 'А', 'С', 'О', 'Н', 'Д'],
  abbreviated: [
    'янв.',
    'фев.',
    'март',
    'апр.',
    'май',
    'июнь',
    'июль',
    'авг.',
    'сент.',
    'окт.',
    'нояб.',
    'дек.',
  ],
  wide: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
  ],
};

if (ruLocale && ruLocale.localize) {
  ruLocale.localize.month = buildLocalizeFn({
    values: monthValues,
    defaultWidth: 'wide',
    defaultFormattingWidth: 'wide',
  });
}

const locales: { [key: string]: any } = {
  ru: ruLocale,
  en: enLocale,
  cz: csLocale,
};

enum DeliveryType {
  self = 'self',
  delivery = 'delivery',
}
const useStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    autoLoc: {
      width: '100%',
    },
    totalCard: {
      width: '35%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    bonusActive: {
      backgroundColor: theme.palette.primary.main,
      width: '33%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: '#FFFFFF',
      },
    },
    btn: {
      width: '33%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    orderBtn: {
      width: 300,
      height: 50,
      [theme.breakpoints.down('sm')]: {
        width: 150,
      },
    },
  };
});

const getInitialValues = (
  cart: ICart | null,
  isAuthorized: boolean,
  me: IUser | undefined,
  localLocation: ILocation,
  guestCart: IGuestCart | null
) => {
  let phone = '';
  let receivedAt = '';

  if (isAuthorized && cart) {
    phone =
      cart.phone && cart.phone.replace(`+ `, '')[0] === '1'
        ? `1${cart.phone}`
        : cart.phone || '';
  } else {
    phone = guestCart?.phone || '';
  }

  if (isAuthorized && cart && cart.received_at) {
    receivedAt = cart.received_at.replace(/\+.*/, '');
  } else if (guestCart) {
    receivedAt = guestCart
      ? guestCart.received_at
      : moment(Date.now()).format('YYYY-MM-DD[T]HH:mm');
  }

  return {
    deliveryType: DeliveryType.delivery,
    location: {
      id: undefined,
      address: isAuthorized
        ? me?.location?.address || localLocation?.address || ''
        : localLocation?.address || '',
      lng: isAuthorized ? me?.location?.lng || 0 : localLocation?.lng || 0,
      lat: isAuthorized ? me?.location?.lat || 0 : localLocation?.lat || 0,
      city: '',
      country: '',
      house: '',
      province: '',
      street: '',
      working_times: undefined,
    },
    phone,
    fio: isAuthorized && cart ? cart.fio : guestCart?.fio || '',
    email: isAuthorized && cart ? cart.email : guestCart?.email || '',
    received_at: receivedAt,
    useBonuse: 0,
  };
};

const CategoriesListPage: React.FC<TPropsFromRedux> = ({
  cart,
  setProductCount,
  setProductCountLoading,

  loadingLocations,
  locations,
  fetchLocations,
  clearLocations,

  checkoutLoading,
  checkoutSuccess,
  checkoutError,
  clearCheckout,
  checkout,

  stores,
  clearStores,
  fetchStrores,
  storesLoading,
  storesError,
  companyId,
  guestCart,
  isAuthorized,
  isAdmin,
  setCountGuestCart,

  byCoordsAddress,
  byCoordsLoading,
  byCoordsSuccess,
  setInfoUserGuestCart,
  me,
  fetchMe,
  fetchCompanies,
}) => {
  const intl = useIntl();
  const classes1 = useStyles();
  const { classes } = classes1;
  const theme = useTheme();
  const navigate = useNavigate();
  const fm = useFormatMessage();
  const {
    data: countries,
    loading: countriesLoading,
    makeRequest,
  } = useAxiosGetCallback<any>();
  const { countryId, setCountryId, countryData } = useHandleCountrySelect(
    countries,
    me?.country.id
  );
  const { payOperation } = useOpenPayWidget();
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [isImageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [pickupLocation, setPickupLocation] = useState<IStore | null>(null);
  const [shippingLocation, setShippingLocation] = useState<ILocation | null>(null);
  const [bonusesSpent, setBonusesSpent] = useState(false);
  const [changeItemCart, setChangeItemCart] = useState(true);
  const [promoOpen, setPromoOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);

  const filterPickupStores = useMemo(() => {
    const newStores = stores.reduce<IStore[]>((accumulator, item) => {
      Boolean(item.location?.pickup_point) && accumulator.push(item);
      return accumulator;
    }, []);
    return newStores;
  }, [stores, stores.length]);

  const storesLenght = useMemo(
    () => filterPickupStores.length > 0,
    [filterPickupStores.length, filterPickupStores]
  );

  const localLocation = useMemo(() => {
    const localLocation = localStorage.getItem('location');
    const parseLocalLocation = localLocation && JSON.parse(localLocation);
    return parseLocalLocation;
  }, []);
  const localLocationDelivery = useMemo(() => {
    const localLocation = localStorage.getItem('locationDelivery');
    const parseLocalLocation = localLocation && JSON.parse(localLocation);
    return parseLocalLocation;
  }, []);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: getInitialValues(cart, isAuthorized, me, localLocation, guestCart),

      validationSchema: () =>
        Yup.lazy(values => {
          return values.deliveryType === DeliveryType.delivery
            ? Yup.object().shape({
                // locationDelivery: Yup.object({
                //   address: Yup.string()
                //     .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
                //     .nullable(),
                // }),
                location: Yup.object({
                  address: Yup.string()
                    .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
                    .nullable(),
                }),
                // received_at: Yup.string().required(
                //   intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
                // ),
                // phone:
                //   values.deliveryType === DeliveryType.delivery
                //     ? Yup.string()
                //         .test(
                //           'len',
                //           intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' }),
                //           val => {
                //             const valLengthWithoutDashes = val
                //               ?.replace(`+`, '')
                //               .replace(/-|_/g, '')
                //               .replace(/ /g, '')
                //               .replace(/[{()}]/g, '').length;
                //
                //             return valLengthWithoutDashes === 11;
                //           }
                //         )
                //         .required(intl.formatMessage({ id: 'AUTH.INPUT.PHONE_REQUIRED' }))
                //     : Yup.mixed(),
                // received_at: Yup.string().required(
                //   intl.formatMessage({ id: 'AUTH.INPUT.DATE_REQUIRED' })
                // ),
              })
            : Yup.object().shape({
                // location: Yup.object({
                //   address: Yup.string()
                //     .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' }))
                //     .nullable(),
                // }),
              });
        }),

      onSubmit: async values => {
        let total = 0;
        if (cart) {
          total =
            values.deliveryType === DeliveryType.self
              ? cart.order_summ
              : cart.order_summ + cart.delivery_cost;
        } else {
          total = guestCart?.order_summ || 0;
        }

        if (!isAuthorized) {
          const { location } = values;

          values.deliveryType === DeliveryType.delivery && location
            ? localStorage.setItem(
                'location',
                JSON.stringify(location.address.trim().length > 0 ? location : null)
              )
            : localStorage.removeItem('location');

          setInfoUserGuestCart({
            fio: values.deliveryType === DeliveryType.delivery ? values.fio : '',
            email: values.deliveryType === DeliveryType.delivery ? values.email : '',
            phone: values.deliveryType === DeliveryType.delivery ? values.phone : '',
            received_at: moment(values.received_at).format('YYYY-MM-DD[T]HH:mm:ss') || '',
            isPickup: values.deliveryType === DeliveryType.self,
            pickupStore: values.deliveryType === DeliveryType.self ? pickupLocation : null,
            address:
              values.deliveryType === DeliveryType.delivery
                ? values.location.address
                : values.location.address,
          });
          setAuthDialogOpen(true);
        } else {
          const lastPrice = bonusesSpent ? total - cart!.can_use_bonuses : total;
          payOperation(cart?.id!, lastPrice, () =>
            checkout({
              data: {
                ...values,
                phone: `${countryData.code}${values.phone}`,
                fio: values.deliveryType === DeliveryType.delivery ? values.fio : '',
                email: values.deliveryType === DeliveryType.delivery ? values.email : '',
                received_at: moment(values.received_at).format('YYYY-MM-DD[T]HH:mm:ss'),
                useBonuse: values.useBonuse,
                address: values.location?.address || '',
                deliveryType: values.deliveryType,
              },
            })
          );
        }
      },
    });

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'CART.HEADER.LIST' }),
    btnTitle: changeItemCart
      ? intl.formatMessage({ id: 'CART.SHORT.VIEW' })
      : intl.formatMessage({ id: 'CART.DETAILED.VIEW' }),
    onPress() {
      setChangeItemCart(!changeItemCart);
    },
  });

  setLayoutFooter({ show: true });

  useCrudSnackbar({
    success: checkoutSuccess,
    error: checkoutError,
    clear: clearCheckout,
    successMessage: intl.formatMessage({ id: 'CART.CHECKOUT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${checkoutError}`,
    afterSuccessOrError: () => navigate('/orders/list/full'),
  });

  useCrudSnackbar({
    success: false,
    error: storesError,
    clear: clearStores,
    successMessage: '',
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${storesError}`,
  });
  useEffect(() => {
    makeRequest('/api/phone_codes');
  }, []);

  useEffect(() => {
    if (me?.country?.id) {
      setCountryId(me.country.id);
    }
    // localLocationDelivery && setFieldValue('location', localLocationDelivery);
    localLocationDelivery && setFieldValue('locationDelivery', localLocationDelivery);
  }, [me]);

  useEffect(() => {
    const pickupStore = localStorage.getItem('pickupStore');
    const parsePickupStore: IStore | null = pickupStore ? JSON.parse(pickupStore) : null;
    if (parsePickupStore) {
      setFieldValue('deliveryType', DeliveryType.self);
      setFieldValue('location', parsePickupStore.location);
      setPickupLocation(parsePickupStore);
      localStorage.removeItem('pickupStore');
    } else {
      localLocationDelivery && setFieldValue('locationDelivery', localLocationDelivery);
      // localLocationDelivery && setFieldValue('location', localLocationDelivery);
    }
    fetchMe();
  }, []);

  useEffect(() => {
    fetchCompanies({ page: 1, perPage: 12 });
  }, []);

  useEffect(() => {
    if (me && me.location) {
      setFieldValue('location', me.location);
      setShippingLocation(me.location);
    } else {
      setShippingLocation(localLocation);
    }
    // return () => {
    //   localStorage.removeItem('locationDelivery');
    // };
  }, [me]);
  //
  useEffect(() => {
    if (byCoordsSuccess) {
      if (byCoordsAddress) {
        setFieldValue('location', byCoordsAddress);
      }
    }
  }, [byCoordsSuccess]);

  useEffect(() => {
    if (isAuthorized) {
      if (companyId) {
        fetchStrores({ companyId });
      }
    } else if (guestCart && guestCart.cartCompanyId) {
      fetchStrores({ companyId: guestCart.cartCompanyId });
    }
  }, [isAuthorized, companyId]);

  useEffect(() => {
    values.deliveryType === DeliveryType.delivery
      ? setFieldValue('location', shippingLocation || {})
      : setFieldValue('location', pickupLocation?.location || {});
  }, [values.deliveryType, shippingLocation]);

  const handleImage = useCallback(
    (url: any) => {
      if (isImageOpen) {
        setImageUrl(null);
        setImageOpen(false);
      } else {
        setImageUrl(url);
        setImageOpen(true);
      }
    },
    [isImageOpen, imageUrl]
  );

  const cartTotal = useMemo(() => {
    let total = 0;

    if (cart) {
      total =
        values.deliveryType === DeliveryType.self
          ? cart.order_summ
          : cart.order_summ + cart.delivery_cost;
    } else {
      total = guestCart?.order_summ || 0;
    }

    return thousands(String(total));
  }, [values.deliveryType, cart, guestCart]);

  let cartEmpty = true;

  if (isAuthorized) {
    cartEmpty = !cart?.items.length;
  } else {
    cartEmpty = !guestCart?.items.length;
  }
  const alertWindow = useCallback(() => {
    setAlertOpen(true);
  }, []);
  if (storesLoading) return <Preloader />;

  return (
    <>
      {imageUrl && <ImageDialog open={isImageOpen} handleClose={handleImage} url={imageUrl} />}
      <AlertDialog
        open={isAlertOpen}
        message={intl.formatMessage({ id: 'CART.PRODUCTS.STOCK.OVER' })}
        okText=''
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        handleClose={() => {
          setAlertOpen(false);
        }}
        handleAgree={() => {
          setAlertOpen(false);
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locales[intl.locale]}>
        <DatePicker
          // id='received_at'
          // variant='inline'
          // ampm={false}
          disablePast
          value={values.received_at ? dayjs(values.received_at) : dayjs()}
          minDate={dayjs(Date.now())}
          onChange={date => setFieldValue('received_at', dayjs(date).toString())}
          // InputProps={{ readOnly: true }}
          // error={Boolean(touched.received_at && errors.received_at)}
          // helperText={touched.received_at && errors.received_at}
        />
      </LocalizationProvider>
      <Row style={{ marginTop: 10 }}>
        <Col>
          {!cartEmpty && (cart || guestCart) ? (
            <div className={classes.container}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                {changeItemCart ? (
                  <ItemCart
                    cart={cart || guestCart!}
                    setProductCount={setProductCount}
                    setCountGuestCart={setCountGuestCart}
                    isAuthorized={isAuthorized}
                    setProductCountLoading={setProductCountLoading}
                    isAdmin={isAdmin}
                    location={
                      values.deliveryType === DeliveryType.self && values.location.id
                        ? values.location
                        : undefined
                    }
                    handleImage={handleImage}
                    alertWindow={alertWindow}
                  />
                ) : (
                  <CartItems
                    cart={cart || guestCart!}
                    setProductCount={setProductCount}
                    setCountGuestCart={setCountGuestCart}
                    isAuthorized={isAuthorized}
                    setProductCountLoading={setProductCountLoading}
                    isAdmin={isAdmin}
                    location={
                      values.deliveryType === DeliveryType.self && values.location.id
                        ? values.location
                        : undefined
                    }
                    handleImage={handleImage}
                    alertWindow={alertWindow}
                  />
                )}
              </div>

              <Card className={classes.totalCard}>
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <Typography component='h6' variant='h6'>
                        {fm('CART.PRODUCTS')} - {cart?.goods_num || guestCart?.goods_num}
                      </Typography>

                      <Typography component='h6' variant='h6'>
                        {thousands(String(cart?.order_summ || guestCart?.order_summ))} ₽
                      </Typography>
                    </div>

                    <RadioGroup
                      name='deliveryType'
                      value={values.deliveryType}
                      onChange={handleChange}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value={DeliveryType.delivery}
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'CART.DELIVERY_TYPE.SHIPPING' })}
                      />
                      <FormControlLabel
                        value={DeliveryType.self}
                        control={<Radio />}
                        label={intl.formatMessage({ id: 'CART.DELIVERY_TYPE.SELF' })}
                        disabled={!storesLenght}
                      />
                    </RadioGroup>

                    {values.deliveryType === DeliveryType.delivery ? (
                      <AutocompleteLocations
                        options={locations || []}
                        loading={loadingLocations || byCoordsLoading}
                        inputValue={values.location}
                        editable
                        label={intl.formatMessage({
                          id: 'STORE.INPUT.LOCATION',
                        })}
                        inputClassName={classes.autoLoc}
                        inputError={Boolean(
                          touched.location && errors.location && errors.location.address
                        )}
                        inputHelperText={
                          touched.location && errors.location && errors.location.address
                        }
                        fetchLocations={fetchLocations}
                        clearLocations={clearLocations}
                        setSelectedLocation={location => {
                          setShippingLocation(location || null);
                          location
                            ? setFieldValue('location', location)
                            : setFieldValue('location', {});
                        }}
                        handleBlur={handleBlur}
                        disable={false}
                      />
                    ) : (
                      <TextField
                        select
                        type='country'
                        label={intl.formatMessage({
                          id: 'STORE.INPUT.LOCATION',
                        })}
                        margin='normal'
                        className='kt-width-full'
                        variant='outlined'
                        value={pickupLocation?.id || undefined}
                        onChange={e => {
                          const store = filterPickupStores.find(
                            store => store.id === +e.target.value
                          );
                          if (store) {
                            setPickupLocation(store);
                            setFieldValue('location', store.location);
                          }
                        }}
                        error={Boolean(
                          touched.location && errors.location && errors.location.address
                        )}
                        helperText={
                          touched.location && errors.location && errors.location.address
                        }
                      >
                        {filterPickupStores.map(item => (
                          <MenuItem key={item.id} value={item.id || 0}>
                            {item?.location?.address || 'No address'}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    {/* {values.deliveryType === DeliveryType.delivery && ( */}
                    <>
                      <Typography variant='h6'>
                        {intl.formatMessage({ id: 'ORDER.RECIPIENT.INFORMATION' })}
                      </Typography>

                      {/* <SelectField
                        label={intl.formatMessage({ id: 'USER.INPUT.COUNTRY_CODE' })}
                        data={countries}
                        selectedValue={countryId}
                        setSelectedValue={setCountryId}
                        variant='outlined'
                        renderItemText={item =>
                          intl.locale === 'ru' ? item.ru_name : item.en_name
                        }
                        // disabled={disabledPhone}
                      /> */}

                      <CountrySelect
                        label={intl.formatMessage({ id: 'USER.INPUT.COUNTRY_CODE' })}
                        countries={countries}
                        countryId={countryId}
                        setCountryId={setCountryId}
                        countriesLoading={countriesLoading}
                        showCode
                      />
                      {countryData && (
                        <IMaskPhoneInput
                          mask={countryData.mask}
                          onBlur={handleBlur}
                          value={values.phone}
                          onChange={(e: any) => {
                            setFieldValue(
                              'phone',
                              e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                            );
                          }}
                          type='tel'
                          label={intl.formatMessage({
                            id: 'AUTH.INPUT.PHONE',
                          })}
                          margin='normal'
                          className='kt-width-full'
                          name='phone'
                          // helperText={touched.phone && errors.phone}
                          // error={Boolean(touched.phone && errors.phone)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                {' '}
                                +{countryData.code}{' '}
                              </InputAdornment>
                            ),
                          }}
                          variant='outlined'
                        />
                      )}
                      <TextField
                        type='text'
                        label={fm('CART.INPUT.NAME')}
                        margin='normal'
                        name='fio'
                        value={values.fio}
                        helperText={touched.fio && errors.fio}
                        error={Boolean(touched.fio && errors.fio)}
                        variant='outlined'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <TextField
                        type='text'
                        label='Email'
                        margin='normal'
                        name='email'
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                        variant='outlined'
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </>
                    {/* )} */}
                    <div style={{ marginTop: 8 }}>
                      <Typography variant='h6'>
                        {intl.formatMessage({ id: 'ORDER.BUYER.DELIVERY.DATE' })}
                      </Typography>
                    </div>

                    {/* {values.deliveryType === DeliveryType.delivery && (
                      <div style={{ marginTop: 8 }}>
                        <Typography variant='h6'>
                          {intl.formatMessage({ id: 'ORDER.BUYER.DELIVERY.DATE' })}
                        </Typography>
                        <MuiPickersUtilsProvider
                          locale={locales[intl.locale]}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            id='received_at'
                            variant='inline'
                            // ampm={false}
                            disablePast
                            // format='MM/dd/yyyy HH:mm'
                            format='MM/dd/yyyy'
                            value={values.received_at}
                            minDate={Date.now()}
                            onChange={date => {
                              setFieldValue('received_at', date);
                            }}
                            InputProps={{ readOnly: true }}
                            inputVariant='outlined'
                            error={Boolean(touched.received_at && errors.received_at)}
                            helperText={touched.received_at && errors.received_at}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )} */}
                    <Divider
                      style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}
                    />
                    {isAuthorized && (
                      <div style={{ width: '100%' }}>
                        <ButtonGroup
                          color='primary'
                          aria-label='outlined primary button group'
                          style={{ width: '100%' }}
                        >
                          <Button
                            className={bonusesSpent ? classes.bonusActive : classes.btn}
                            onClick={() => {
                              setFieldValue('useBonuse', cart!.can_use_bonuses);
                              setBonusesSpent(true);
                            }}
                          >
                            <div>
                              <Typography
                                style={{
                                  color: bonusesSpent ? '#fff' : theme.palette.primary.main,
                                }}
                              >
                                Списать
                              </Typography>
                              <Typography
                                style={{
                                  color: bonusesSpent ? '#fff' : theme.palette.primary.main,
                                }}
                              >
                                {cart!.can_use_bonuses} бонусов
                              </Typography>
                            </div>
                          </Button>
                          <Button
                            className={!bonusesSpent ? classes.bonusActive : classes.btn}
                            onClick={() => {
                              setFieldValue('useBonuse', 0);
                              setBonusesSpent(false);
                            }}
                          >
                            <div>
                              <Typography
                                style={{
                                  color: !bonusesSpent ? '#fff' : theme.palette.primary.main,
                                }}
                              >
                                Не списывать
                              </Typography>
                            </div>
                          </Button>
                          <Button
                            className={classes.btn}
                            onClick={() => {
                              setPromoOpen(true);
                            }}
                          >
                            <div>
                              <Typography>
                                Применить <br /> промокод
                              </Typography>
                            </div>
                          </Button>
                        </ButtonGroup>
                        <Divider
                          style={{
                            marginTop: theme.spacing(3),
                            marginBottom: theme.spacing(3),
                          }}
                        />
                      </div>
                    )}

                    {!bonusesSpent || !isAuthorized ? (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: theme.spacing(3),
                        }}
                      >
                        <Typography component='h6' variant='h6'>
                          {fm('CART.TOTAL')}
                        </Typography>

                        <Typography component='h6' variant='h6'>
                          <b>{cartTotal} ₽</b>
                        </Typography>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: theme.spacing(3),
                            fontSize: 21,
                            color: '#ffce00',
                          }}
                        >
                          <Typography component='h6' variant='h6'>
                            {fm('CART.TOTAL.NO.BONUSES')}
                          </Typography>
                          <Typography component='h6' variant='h6'>
                            {cartTotal} ₽
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: theme.spacing(3),
                          }}
                        >
                          <Typography component='h6' variant='h6'>
                            {fm('CART.TOTAL.BONUSES.SPENT')}
                          </Typography>

                          <Typography component='h6' variant='h6'>
                            {cart!.can_use_bonuses} бонусов
                          </Typography>
                        </div>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: theme.spacing(3),
                          }}
                        >
                          <Typography component='h6' variant='h6'>
                            {fm('CART.TOTAL')}
                          </Typography>

                          <Typography component='h6' variant='h6'>
                            <b>
                              {thousands(
                                String(
                                  Number(cartTotal.replace(/\s/g, '')) - cart!.can_use_bonuses
                                )
                              )}{' '}
                              ₽
                            </b>
                          </Typography>
                        </div>
                      </>
                    )}
                    <ButtonWithLoader disabled={checkoutLoading} loading={checkoutLoading}>
                      <FormattedMessage id='CART.CHECKOUT.BUTTON' />
                    </ButtonWithLoader>
                  </form>
                </CardContent>
              </Card>
            </div>
          ) : (
            <Typography className={classes.empty} component='h5' variant='h5'>
              {intl.formatMessage({ id: 'COMMON.LIST.EMPTY' })}
            </Typography>
          )}
        </Col>
      </Row>
      <AuthDialog
        initPhone={values.phone}
        initEmail={values.email}
        hideGuestBtn={Boolean(true)}
        isOpen={authDialogOpen}
        handleClose={() => setAuthDialogOpen(false)}
      />

      <Modal
        open={promoOpen}
        onClose={() => setPromoOpen(false)}
        title={fm('PROMOCODE.APPLY.TITLE')}
        content={<Bonuses me={me!} isCart />}
        actions={[
          {
            title: fm('COMMON.BUTTON.CLOSE'),
            onClick: () => setPromoOpen(false),
          },
        ]}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    cart: state.cart.cart,
    setProductCountLoading: state.cart.setProductCountLoading,

    loadingLocations: state.yaLocations.loading,
    locations: state.yaLocations.yaLocations,

    checkoutLoading: state.cart.checkoutLoading,
    checkoutSuccess: state.cart.checkoutSuccess,
    checkoutError: state.cart.checkoutError,

    stores: state.cart.stores,
    storesLoading: state.cart.storesLoading,
    storesSuccess: state.cart.storesSuccess,
    storesError: state.cart.storesError,
    guestCart: state.cart.guestCart,
    isAuthorized: state.auth.user != null,
    companyId: state.cart.companyId,
    isAdmin: state.auth.user?.is_admin,

    byCoordsAddress: state.yaLocations.addressByCoords,
    byCoordsLoading: state.yaLocations.getLoacationByCoordsLoading,
    byCoordsSuccess: state.yaLocations.getLoacationByCoordsSuccess,

    companies: state.companies.companies,
  }),
  {
    setProductCount: cartActions.setProductCountRequest,
    fetchLocations: locationActions.fetchRequest,
    clearLocations: locationActions.clear,

    fetchByCoords: locationActions.fetchByCoordsRequest,

    clearCheckout: cartActions.clearCheckout,
    checkout: cartActions.checkoutRequest,

    clearStores: cartActions.clearStores,
    fetchStrores: cartActions.storesRequest,
    setCountGuestCart: cartActions.setCountGuestCart,
    setInfoUserGuestCart: cartActions.setInfoUserGuestCart,
    fetchMe: profileActions.fetchRequest,

    fetchCompanies: companiesActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CategoriesListPage);
