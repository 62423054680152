import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonWithLoader from '../../../../../components/other/Buttons/ButtonWithLoader';
import { IUser, UserRoles } from '../../../../../interfaces/user';
import homeStyles from '../../../../../constants/homeStyles';
import { useStylesEditPage } from '../hooks/useStyleEditPage';
import { useDefineUserRole, useFormatMessage } from '../../../../../hooks';
import { IMaskPhoneInput } from '../../../../auth/components/CheckInSystem';
import { RedOutlinedButton } from '../../../../../components/other/Buttons';

interface TProps {
  user: IUser | null;
  formik: any;
  disabledRole: boolean;
  role: string;
  id?: string;
  setOpen: (value: boolean) => void;
  editLoading?: boolean;
  handleSubmit: () => void;
}

const FormUser: React.FC<TProps> = ({
  user,
  formik,
  disabledRole,
  role,
  id,
  setOpen,
  editLoading,
  handleSubmit,
}) => {
  const { values, setFieldValue, handleBlur, handleChange, touched, errors } = formik;
  const fm = useFormatMessage();
  const isVendorStaff = useDefineUserRole(user, 'ROLE_VENDOR_STAFF');
  const isVendor = useDefineUserRole(user, 'ROLE_VENDOR');

  const classes = useStylesEditPage();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <div className={homeClasses.classes.form}>
      {values.role !== UserRoles.ROLE_VENDOR && (
        <>
          <TextField
            type='text'
            label={fm('USER.EDIT.LOGIN')}
            margin='normal'
            name='login'
            value={values.login}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.login && errors.login}
            error={Boolean(touched.login && errors.login)}
          />

          <TextField
            type='text'
            label={fm('USER.EDIT.EMAIL')}
            margin='normal'
            name='email'
            value={values.email}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.email && errors.email}
            error={Boolean(touched.email && errors.email)}
          />
        </>
      )}

      <div className={classes.dates}>
        <IMaskPhoneInput
          mask='(***) ***-**-**'
          // mask={countryData.mask}
          onBlur={handleBlur}
          value={values.phoneNumbers}
          onChange={(e: any) => {
            setFieldValue(
              'phoneNumbers',
              e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
            );
          }}
          type='tel'
          label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
          //   style={{ marginLeft: 5 }}
          margin='normal'
          className='kt-width-full'
          name='phoneNumbers'
          helperText={touched.phoneNumbers && errors.phoneNumbers}
          error={Boolean(touched.phoneNumbers && errors.phoneNumbers)}
          variant='outlined'
          InputProps={{
            startAdornment: <InputAdornment position='start'> +7 </InputAdornment>,
          }}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position='start'>
          //       {' '}
          //       +{countryData.code}{' '}
          //     </InputAdornment>
          //   ),
          // }}
        />
      </div>

      {values.role !== UserRoles.ROLE_VENDOR && (
        <>
          <TextField
            autoComplete='off'
            type='password'
            label={fm('USER.EDIT.PASSWORD')}
            margin='normal'
            name='password'
            value={values.password}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.password && errors.password}
            error={Boolean(touched.password && errors.password)}
          />

          <TextField
            autoComplete='off'
            type='password'
            margin='normal'
            label={fm('USER.EDIT.PASSWORD.REPEAT')}
            name='password2'
            value={values.password2}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.password2 && errors.password2}
            error={Boolean(touched.password2 && errors.password2)}
          />
        </>
      )}

      <FormControlLabel
        control={
          <Switch
            className={homeClasses.classes.switch}
            size='small'
            name='active'
            checked={values.active || false}
            onChange={handleChange}
          />
        }
        label={fm('PRODUCT.EDIT.STATUS')}
      />

      <TextField
        select
        margin='normal'
        label={fm('USER.EDIT.ROLE')}
        value={values.role}
        onChange={handleChange}
        name='role'
        variant='outlined'
        helperText={touched.role && errors.role}
        error={Boolean(touched.role && errors.role)}
        disabled={disabledRole}
      >
        <MenuItem hidden={!isVendorStaff && !isVendor} value={UserRoles.ROLE_VENDOR}>
          {fm('USER.ROLES.VENDOR')}
        </MenuItem>
        <MenuItem hidden={!(role === 'ROLE_MANAGER')} value={UserRoles.ROLE_ADMIN}>
          {fm('USER.ROLES.ADMIN')}
        </MenuItem>
        <MenuItem hidden={!(role === 'ROLE_MANAGER')} value={UserRoles.ROLE_MANAGER}>
          {fm('USER.ROLES.MANAGER')}
        </MenuItem>
      </TextField>

      <TextField
        type='text'
        label={intl.formatMessage({ id: 'USER.EDIT.FIO' })}
        margin='normal'
        name='fio'
        value={values.fio || ''}
        variant='outlined'
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={touched.fio && errors.fio}
        error={Boolean(touched.fio && errors.fio)}
      />

      {/* <TextField
        type='text'
        label={intl.formatMessage({ id: 'USER.EDIT.LAST_NAME' })}
        margin='normal'
        name='last_name'
        value={values.last_name || ''}
        variant='outlined'
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={touched.last_name && errors.last_name}
        error={Boolean(touched.last_name && errors.last_name)}
      />

      <TextField
        type='text'
        label={intl.formatMessage({ id: 'USER.EDIT.FIRST_NAME' })}
        margin='normal'
        name='first_name'
        value={values.first_name || ''}
        variant='outlined'
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={touched.first_name && errors.first_name}
        error={Boolean(touched.first_name && errors.first_name)}
      />

      <TextField
        type='text'
        label={intl.formatMessage({ id: 'USER.EDIT.MIDDLE_NAME' })}
        margin='normal'
        name='middle_name'
        value={values.middle_name || ''}
        variant='outlined'
        onBlur={handleBlur}
        onChange={handleChange}
        helperText={touched.middle_name && errors.middle_name}
        error={Boolean(touched.middle_name && errors.middle_name)}
      /> */}

      {/* <div className={classes.companyField}>
                  <TextField
                    label={fm('COMPANY.FORM.COMPANY')}
                    margin='normal'
                    name={values.company?.name ? 'company' : 'companyInn'}
                    placeholder={fm('COMPANY.SEARCH.BY_INN')}
                    value={values.company?.name || values.companyInn}
                    onChange={handleChange}
                    variant='outlined'
                    disabled={!!values.company?.name}
                    InputProps={{
                      endAdornment: values.company?.name ? (
                        <IconButton onClick={deleteCompany}>
                          <DeleteIcon color='error' fontSize='small' />
                        </IconButton>
                      ) : (
                        <IconButton onClick={openSearchCompaniesDialog}>
                          <SearchIcon color='primary' fontSize='small' />
                        </IconButton>
                      ),
                    }}
                  />
                  {!values.company?.name && me?.is_admin && (
                    <Button
                      style={{
                        marginTop: 8,
                        marginLeft: 10,
                        height: 52,
                      }}
                      variant='outlined'
                      color='primary'
                      onClick={openCompaniesDialog}
                    >
                      {fm('COMPANY.BUTTON.ALL')}
                    </Button>
                  )}
                </div> */}

      <div className={classes.actions}>
        <ButtonWithLoader
          variant='contained'
          color='secondary'
          type='button'
          marginRight={20}
          disabled={editLoading}
          loading={editLoading}
          onPress={() => navigate(-1)}
        >
          {fm('COMMON.BUTTON.CLOSE')}
        </ButtonWithLoader>

        <ButtonWithLoader
          marginRight={20}
          disabled={editLoading}
          loading={editLoading}
          onPress={handleSubmit}
        >
          {/* {id ? fm('USER.BUTTON.EDIT') : fm('USER.BUTTON.ADD')} */}
          {fm(values.role === 'ROLE_VENDOR' ? 'AUTH.MAIN.BUTTON' : 'USER.BUTTON.EDIT')}
        </ButtonWithLoader>

        {id && (
          <ButtonWithLoader
            onPress={() => {
              setOpen(true);
            }}
            type='button'
            variant='contained'
            color='error'
          >
            {fm('COMMON.BUTTON.DELETE')}
          </ButtonWithLoader>
        )}
      </div>
    </div>
  );
};

export default FormUser;
